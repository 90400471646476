import { useRouter } from '../../routes/Router.hooks';
import {
	getNotifications,
	getSettings,
	getUser
} from '../Settings/Settings.data';
import { getCustomerTypes, getTickets } from '../Shop/Shop.data';
import { Loader } from '../../components/Loader';

export default (): any => {
	const route = useRouter();
	// we dont want the user to see the login page ofc
	const settings = getSettings( true );
	const tickets = getTickets( true );
	const user = getUser( true );
	const notifications = getNotifications( true );
	const customerTyp = getCustomerTypes( true );

	if (
		!settings.isLoading &&
		!tickets.isLoading &&
		!user.isLoading &&
		!notifications.isLoading &&
		!customerTyp.isLoading &&
		settings.data &&
		tickets.data &&
		user.data &&
		notifications.data &&
		customerTyp.data
	) {
		if ( route.query.redirect ) {
			route.push( route.query.redirect );
		} else {
			route.push( '/' );
		}
	}

	return <Loader />;
};
