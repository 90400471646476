import { useState, useEffect } from 'react';
import _ from 'lodash';
import 'react-datetime/css/react-datetime.css';
import {
	Accordion,
	AccordionItem,
	AccordionItemState,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { ReactComponent as PlusSVG } from '../../assets/vectors/plus.svg';
import { ReactComponent as MinusSVG } from '../../assets/vectors/minus.svg';
import { Divider } from '../../components/Divider';
import { HeyServer } from '../../utils/server';
import { ReactComponent as CameraSVG } from '../../assets/vectors/camera.svg';
import { ShopPresistor } from './Shop.data';

type CouponReturnType = {
	typ: 'COUPON' | 'INCERT_VALUE' | 'INCERT_PACKAGE';
	value: {
		value_percent: number;
		customer_types: number[];
		ticket_types: number[];
		description: string;
		is_disabled: boolean;
	};
};
export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	const [serverError, setServerError] = useState( '' );

	// when new price is being retrieved from the server
	const [submitting, setSubmitting] = useState( false );
	const [openCamera, setOpenCamera] = useState( false );
	const [code, setCode] = useState( '' );
	const [voucherImage, setVoucherImage] = useState<Blob | MediaSource | null>();

	useEffect( () => {
		setCode( '' );
		setVoucherImage( null );
	}, [route.location] );

	const createOrder = ( redirectUrl: string ) =>
		// create a new order if not already
		HeyServer.post( 'order/' )
			.then( ( order: any ) => {
				ShopPresistor().setActiveOrder( order );
				return route.push( redirectUrl );
			} )
			.catch( ( e ) => {
				setServerError( e );
				setSubmitting( false );
			} );

	const applyVoucher = ( newCode = '' ) => {
		if ( !newCode ) {
			return;
		}
		setSubmitting( true );
		const activeOrder: any = ShopPresistor().getActiveOrder();
		ShopPresistor().flagReset();
		// get code information
		HeyServer.post( '/order/code_type/', { code: newCode } )
			.then( ( res: any ) => {
				let url = '/shop';
				// we want to define, that if the user has no ongoing order,
				// simply activate its flag and redirect to shop after cobjectreating the order
				switch ( res.typ ) {
					case 'COUPON':
						ShopPresistor().flagOn( 'incert_coupon', { code: newCode } );
						activeOrder.pk && ( url = `/basket` );
						break;
					case 'INCERT_VALUE':
						ShopPresistor().flagOn( 'incert_voucher', { code: newCode } );
						activeOrder.pk && ( url = `/basket` );
						break;
					case 'INCERT_PACKAGE':
						ShopPresistor().flagOn( 'incert_package', {
							code: newCode,
							supported_type: res.value
						} );
						break;
				}
				// if order already exists, simply just redirect
				if ( activeOrder.pk ) {
					return route.push( url );
				}
				return createOrder( url );
			} )
			.catch( ( e ) => {
				setServerError( e );
				setSubmitting( false );
			} );
	};

	const help_section: any = (
		<Card>
			<Accordion allowZeroExpanded onChange={( v ) => true}>
				<AccordionItem>
					<AccordionItemHeading>
						<AccordionItemButton>
							{t( 'voucher.help.title' )}
							<AccordionItemState>
								{( { expanded } ) =>
									expanded ? (
										<div
											style={{
												position: 'absolute',
												top: '12px',
												right: '15px',
												color: '#4d5152'
											}}
										>
											<MinusSVG width="17" />
										</div>
									) : (
										<div
											style={{
												position: 'absolute',
												top: '12px',
												right: '15px',
												color: '#4d5152'
											}}
										>
											<PlusSVG width="17" />
										</div>
									)
								}
							</AccordionItemState>
						</AccordionItemButton>
					</AccordionItemHeading>
					<AccordionItemPanel>
						<div>
							<p>{t( 'voucher.help.info_1' )}</p>
							<p>
								<strong>{t( 'voucher.help.not_working' )}</strong>
							</p>
							<p>{t( 'voucher.help.info_2' )}</p>
						</div>
					</AccordionItemPanel>
				</AccordionItem>
			</Accordion>
		</Card>
	);
	if ( submitting ) {
		return <Loader />;
	}
	// SWR calls
	return (
		<Page title={t( 'voucher.title' )}>
			<br />
			<Card>
				<div className="VoucherContainer">
					<span>{t( 'voucher.info' )}</span>
					<div className="__fotoUploader">
						<Button
							label={t( 'buttons.voucher' )}
							onClick={() => setOpenCamera( true )}
							Icon={<CameraSVG />}
						/>
						{openCamera && (
							<BarcodeScannerComponent
								width="100%"
								height={300}
								onUpdate={( err, result: any ) => {
									if ( result && result.text ) {
										setOpenCamera( false );
										setCode( result.text );
										applyVoucher( result.text );
									}
								}}
							/>
						)}
						{voucherImage instanceof File && (
							<img src={URL.createObjectURL( voucherImage )} alt="ausweisfoto" />
						)}
						<Divider label={t( 'basics.or' )} />
						<span>{t( 'voucher.input_label' )}</span>
						<Input
							name="voucher"
							placeholder="XX12345"
							type={code ? 'with-button' : 'no-label'}
							onChange={( value ) => setCode( value )}
							onButtonPress={() => applyVoucher( code )}
							value={code}
						/>
						{serverError && (
							<Alert type="error" show>
								<p>{ serverError }</p>
							</Alert>
						)}
					</div>
				</div>
			</Card>
			{help_section}
		</Page>
	);
};
