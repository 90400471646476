import { FC, ReactNode } from 'react';

export const BottomNavigation: FC<BottomNavigationProps> = ( {
	items,
	onClick
}: BottomNavigationProps ) => (
	<div className="BottomNavigation">
		{ items.map( ( { label, Icon, badge, disabled, address, active } ) => (
			<div
				key={ address }
				className={ `BottomNavigation__item${active ? ' active' : disabled ? ' disabled' : ''} ` }
				onClick={ () => ! disabled && onClick( address ) }
				aria-hidden="true"
			>
				{ Icon }
				{ badge !== 0 && badge && <span>{ badge }</span> }

				<p>{ label }</p>
			</div>
		) ) }
	</div>
);

export type NavigationMenuItems = {
	/**
	 * Button text label
	 */
	label: string;

	/**
	 * Vector file as React Element. Ignore its value in Storybook
	 */
	Icon: ReactNode | JSX.Element;

	/**
	 * Red badge number
	 */
	badge?: number | boolean;

	/**
	 * Is disabled or not
	 */
	disabled?: boolean;
	active?: boolean;

	/**
	 * Ignored in compoonent but used outside
	 */
	address: string;
};

export interface BottomNavigationProps {
	/**
	 * BottomNavigation active value
	 */
	items: NavigationMenuItems[];

	/**
	 * Menu click
	 */
	onClick: ( label: string ) => void;
}

BottomNavigation.defaultProps = {};
