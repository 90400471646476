/* eslint-disable no-return-assign */

import { useState } from 'react';
import { Formik } from 'formik';
import { mutate } from 'swr';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { useRouter } from '../../routes/Router.hooks';
import { ReactComponent as EyeSVG } from '../../assets/vectors/eye.svg';
import { CheckBox } from '../../components/CheckBox';
import { PasswordStrengthMeter } from '../../components/modular/PasswordStrengthMeter';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { getUser, getSettings } from '../Settings/Settings.data';
import { isLoggedIn, postMessage } from '../../utils/helpers';
import { Loader } from '../../components/Loader';

export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	getUser( true );

	if ( isLoggedIn() )
		route.push( '/' );

	const [ serverError, setServerError ] = useState( '' );
	const [ passwordView, setPasswordView ] = useState( true );
	const { data, isLoading } = getSettings( false );

	if ( isLoading )
		return <Loader />;

	const onSubmit = ( values, { setSubmitting } ) => {
		HeyServer.post( '/register', values )
			.then( ( res ) => {
				window.localStorage.setItem( 'user_token', JSON.stringify( res ) );
				mutate( ['/user/', true] );
				postMessage( 'USER_LOGIN' );
				let address = `/authenticate?reload=1`;
				const { redirect } = route.query;

				if ( redirect )
					address += `&redirect=${redirect}`;

				return route.push( address );
			} )

			.catch( ( e ) => setServerError( e ) )

			.finally( () => setSubmitting( false ) );
	};

	const { covid19_needed } = data.results[0];

	const validate = ( values ) => {
		const errors: any = {};

		if ( ! values.email ) {
			errors.email = t( 'errors.form.empty', {
				value: 'E-Mail'
			} );
		}

		if ( ! values.pw ) {
			errors.pw = t( 'erros.form.empty', {
				value: t( 'form_labels.password' )
			} );
		}

		if ( ! values.first_name ) {
			errors.first_name = t( 'errors.form.empty', {
				value: t( 'form_labels.name' )
			} );
		}

		if ( ! values.last_name ) {
			errors.last_name = t( 'errors.form.empty', {
				value: t( 'form_labels.surname' )
			} );
		}

		if ( ! values.pp_accepted ) {
			errors.pp_accepted = t( 'form_labels.required' );
		}

		if ( ! values.tos_accepted ) {
			errors.tos_accepted = t( 'form_labels.required' );
		}

		if ( ! values.covid19_accepted && covid19_needed ) {
			errors.covid19_accepted = t( 'form_labels.required' );
		}

		if ( ! values.isPasswordStrong ) {
			errors.pw = t( 'errors.form.password' );
		}

		if ( ! /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test( values.email ) ) {
			errors.email = t( 'errors.form.email' );
		}

		if ( values.country !== 'AT' ) {
			! values.street && ( errors.street = t( 'form_labels.required' ) );
			! values.postal_code && ( errors.postal_code = t( 'form_labels.required' ) );
			! values.city && ( errors.city = t( 'form_labels.required' ) );
		}

		return errors;
	};

	return (
		<Page title={ t( 'login.register_title' ) }>
			{ serverError && (
				<Alert type="error" show>
					<p>{ serverError }</p>
				</Alert>
			) }

			<Card>
				<Formik
					initialValues={ {
						first_name: '',
						last_name: '',
						email: '',
						pw: '',
						country: 'AT',
						pp_accepted: false,
						tos_accepted: false,
						covid19_accepted: false,
						street: '',
						postal_code: '',
						city: ''
					} }
					validate={ validate }
					validateOnChange={ false }
					validateOnBlur={ false }
					onSubmit={ onSubmit }
				>
					{ ( {
						values,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting,
						setFieldValue
					} ) => (
						<form onSubmit={ handleSubmit }>
							<div className="row">
								<Input
									name="first_name"
									type="with-label"
									label={ t( 'form_labels.name' ) }
									onChange={ ( value, e ) => handleChange( e ) }
									value={ values.first_name }
									onErrorMessage={ errors.first_name as string }
								/>

								<Input
									name="last_name"
									type="with-label"
									label={ t( 'form_labels.surname' ) }
									onChange={ ( value, e ) => handleChange( e ) }
									value={ values.last_name }
									onErrorMessage={ errors.last_name as string }
								/>
							</div>

							<Input
								inputType="email"
								name="email"
								type="with-label"
								label="e-mail"
								onChange={ ( value, e ) => handleChange( e ) }
								value={ values.email }
								onErrorMessage={ errors.email as string }
							/>
							<div className="__Input-password">
								<Input
									inputType={ passwordView ? 'password' : 'text' }
									name="pw"
									type="with-label"
									label={ t( 'form_labels.password' ) }
									onChange={ ( value, e ) => handleChange( e ) }
									value={ values.pw }
									onErrorMessage={ errors.pw as string }
									child={ <EyeSVG onClick={ () => setPasswordView( !passwordView ) } /> }
								/>
							</div>

							<PasswordStrengthMeter
								email={ values.email }
								password={ values.pw }
								isAcceptable={ ( s ) => setFieldValue( 'isPasswordStrong', s ) }
							/>

							<Input
								inputType="select"
								name="country"
								type="with-label"
								label={ t( 'form_labels.countries.title' ) }
								selectValues={ [
									{
										optionLabel: 'AT',
										optionValue: t( 'form_labels.countries.at' )
									},
									{
										optionLabel: 'DE',
										optionValue: t( 'form_labels.countries.de' )
									},
									{
										optionLabel: 'IT',
										optionValue: t( 'form_labels.countries.it' )
									},
									{
										optionLabel: 'SI',
										optionValue: t( 'form_labels.countries.si' )
									}
								] }
								onChange={ ( value, e ) => {
									handleChange( e );
								} }
								value={ values.country }
								onErrorMessage={ errors.country as string }
							/>

							{ values.country !== 'AT' && (
								<>
									<Input
										name="street"
										type="with-label"
										label={ t( 'form_labels.street' ) }
										onChange={ ( value, e ) => handleChange( e ) }
										value={ values.street }
										onErrorMessage={ errors.street as string }
									/>

									<div className="row">
										<Input
											name="postal_code"
											type="with-label"
											label={ t( 'form_labels.zip' ) }
											onChange={ ( value, e ) => handleChange( e ) }
											value={ values.postal_code }
											onErrorMessage={ errors.postal_code as string }
										/>

										<Input
											name="city"
											type="with-label"
											label={ t( 'form_labels.place' ) }
											onChange={ ( value, e ) => handleChange( e ) }
											value={ values.city }
											onErrorMessage={ errors.city as string }
										/>
									</div>
								</>
							) }

							<CheckBox
								label={ t( 'basics.policy' ) }
								checked={ values.pp_accepted }
								onChange={ ( v ) => setFieldValue( 'pp_accepted', v ) }
							>
								<>
									{ t( 'form_labels.accept' ) }{ ' ' }
									<a href="/settings/policy">{ t( 'basics.policy' ) }</a>
								</>
							</CheckBox>

							<CheckBox
								label={ t( 'basics.gtc' ) }
								checked={ values.tos_accepted }
								onChange={ ( v ) => setFieldValue( 'tos_accepted', v ) }
							>
								<>
									{ t( 'form_labels.accept' ) }{' '}
									<a href="/settings/terms">{ t( 'basics.gtc' ) }</a>
								</>
							</CheckBox>

							{ covid19_needed && (
								<CheckBox
									label={ t( 'basics.covid19_rules' ) }
									checked={ values.covid19_accepted }
									onChange={ ( v ) => setFieldValue( 'covid19_accepted', v ) }
								>
									<>
										{ t( 'form_labels.accept' ) }{ ' ' }
										<a href="/settings/terms">{ t( 'basics.covid19_rules' ) }</a>
									</>
								</CheckBox>
							) }

							<br />

							<Button
								actionType="submit"
								onClick={ handleSubmit }
								label={ t( 'basics.register' ) }
								disabled={
									isSubmitting ||
									! values.pp_accepted ||
									! values.tos_accepted ||
									( ! values.covid19_accepted && covid19_needed )
								}
							/>
						</form>
					) }
				</Formik>
			</Card>
		</Page>
	);
};
