/* eslint-disable no-return-assign */
import { useEffect } from 'react';

export default ( { children } ) => {
	useEffect( () => {
		window.addEventListener( 'offline', () => ( window.location.href = '/' ) );
		window.addEventListener( 'online', () => window.location.reload() );
		return () => {
			window.removeEventListener( 'offline', () => ( window.location.href = '/' ) );
			window.removeEventListener( 'online', () => window.location.reload() );
		};
	}, [] );
	return children;
};
