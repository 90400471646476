import { Navigate, Router } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { Page } from '../../components/Page';

export default (): any => (
	<Page title="404 Not Found" headerActive={false}>
		<Loader />
		<Navigate to="/" replace />
	</Page>
);
