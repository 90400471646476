import { FC } from 'react';
import { ReactComponent as PlusIcon } from '../../../assets/vectors/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/vectors/minus.svg';
import { Input } from '../../Input';
import { Button } from '../../Button';

export const Counter: FC<CounterProps> = ( {
	lowerLimit = 0,
	upperLimit = false,
	value,
	onNewValue
}: CounterProps ) => (
	<div className="__Counter">
		<Button
			overrideClassName="__Counter-button __Counter-button-minus"
			type="secondary"
			disabled={Boolean( value === 0 || lowerLimit === value )}
			onClick={() => onNewValue( value - 1, value )}
			Icon={<MinusIcon />}
		/>
		<Input
			name="counter-input"
			overrideClassName="__Counter-input"
			placeholder="0"
			readOnly
			onChange={( e ) => e.preventDefault()}
			value={value}
		/>
		<Button
			overrideClassName="__Counter-button __Counter-button-plus"
			type="secondary"
			disabled={upperLimit === false ? upperLimit : upperLimit <= 0}
			onClick={() => onNewValue( value + 1, value )}
			Icon={<PlusIcon />}
		/>
	</div>
);

export interface CounterProps {
	/**
	 * Counter active value
	 */
	value: number;
	/**
	 * When counter value changes
	 */
	onNewValue: ( val: any, oldValue: any ) => any;
	/**
	 * Min count equaling the limit
	 */
	lowerLimit?: number | null;
	/**
	 * Max count equaling the limit
	 * Parent component decides if the limit is on or off
	 */
	upperLimit?: number | boolean;
}
Counter.defaultProps = {
	lowerLimit: 0,
	upperLimit: false
};
