import { FC, useEffect } from 'react';
import zxcvbn from 'zxcvbn';

export const PasswordStrengthMeter: FC<PasswordStrengthMeterProps> = ( {
	email = '',
	password = '',
	isAcceptable
}: PasswordStrengthMeterProps ) => {
	const { score } = zxcvbn( password, email );
	useEffect( () => {
		if ( score > 1 && password.length >= 8 ) {
			isAcceptable( true );
		} else {
			isAcceptable( false );
		}
	}, [score] );
	return (
		<div className="PSM">
			<span className={`${score < 2 && 'disabled'}`} />
			<span className={`${score < 3 && 'disabled'}`} />
			<span className={`${score < 4 && 'disabled'}`} />
		</div>
	);
};

export interface PasswordStrengthMeterProps {
	email: string;
	password: string;
	/**
	 * Is password valid
	 */
	isAcceptable: ( s: boolean ) => void;
}
PasswordStrengthMeter.defaultProps = {};
