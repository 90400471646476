import { FC } from 'react';
import { Button } from '../../Button';
import { Card } from '../../Card';
import { ReactComponent as IconArrowLeft } from '../../../assets/vectors/arrow-left.svg';
import { ReactComponent as IconArrowRight } from '../../../assets/vectors/arrow-right.svg';

export const Pagination: FC<PaginationProps> = ( {
	currentPage,
	maxPages,
	nextPage,
	previousPage
}: PaginationProps ) => (
	<div
		style={ {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			position: 'relative'
		} }
	>
		{ currentPage >= 2 && (
			<Button
				overrideClassName="Button__pagination Button__pagination--left"
				Icon={ <IconArrowLeft /> }
				onClick={ previousPage }
			/>
		) }
		<p>{ `${currentPage} ${maxPages ? `/${maxPages}` : ''}` }</p>
		{ ! ( currentPage === maxPages ) && (
			<Button
				overrideClassName="Button__pagination Button__pagination--right"
				Icon={ <IconArrowRight /> }
				onClick={ nextPage }
			/>
		) }
	</div>
);

export interface PaginationProps {
	/**
	 * Current page index
	 */
	currentPage: number;

	/**
	 * Maximal number of pages
	 */
	maxPages: number | null;

	/**
	 * Callback for next page
	 */
	nextPage: () => any;

	/**
	 * Callback for previous page
	 */
	previousPage: () => any;
}
Pagination.defaultProps = {
	currentPage: 0,
	maxPages: null
};
