/* eslint-disable no-return-assign */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Input } from '../../components/Input';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { useRouter } from '../../routes/Router.hooks';
import { ReactComponent as EyeSVG } from '../../assets/vectors/eye.svg';
import { Divider } from '../../components/Divider';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { isApp, isLoggedIn, postMessage } from '../../utils/helpers';

export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	// we dont want the user to see the login page ofc
	if ( isLoggedIn() ) {
		if ( isApp() ) {
			window.location.href = '/index.html';
		} else {
			window.location.href = '/';
		}
		route.push( '/' );
	}
	const [passwordView, setPasswordView] = useState( true );
	const [serverError, setServerError] = useState( '' );
	const onSubmit = ( values, { setSubmitting } ) => {
		HeyServer.post( '/login', values )
			.then( ( res ) => {
				if ( !res ) {
					setServerError( t( 'login.error' ) );
					return;
				}
				window.localStorage.setItem( 'user_token', JSON.stringify( res ) );
				postMessage( 'USER_LOGIN' );
				let address = `/authenticate`;
				if (
					isLoggedIn() &&
					route.previousPath &&
					!route.previousPath.includes( 'logout' )
				) {
					address += `?redirect=${route.previousPath}`;
				}
				route.push( address );
			} )
			.catch( ( e ) => {
				setServerError( e );
			} )
			.finally( () => setSubmitting( false ) );
	};
	const validate = ( values ) => {
		const errors: any = {};
		if ( !values.user ) {
			errors.user = t( 'erros.form.empty', {
				value: 'E-Mail'
			} );
		}
		if ( !values.pw ) {
			errors.pw = t( 'errors.form.empty', {
				value: t( 'form_labels.password' )
			} );
		}
		if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test( values.user ) ) {
			errors.user = t( 'errors.form.email' );
		}
		return errors;
	};

	return (
		<Page title={t( 'login.title' )}>
			{serverError && (
				<Alert type="error" show>
					<p>{serverError}</p>
				</Alert>
			)}
			<Card>
				<Formik
					initialValues={{ user: '', pw: '' }}
					validate={validate}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={onSubmit}
				>
					{( { values, errors, handleChange, handleSubmit, isSubmitting } ) => (
						<form onSubmit={handleSubmit}>
							<Input
								inputType="email"
								name="user"
								type="with-label"
								label="E-mail"
								cy="textinput__Login-Username"
								onChange={( value, e ) => handleChange( e )}
								value={values.user}
								onErrorMessage={errors.user as string}
							/>
							<div className="__Input-password">
								<Input
									inputType={passwordView ? 'password' : 'text'}
									name="pw"
									type="with-label"
									label={t( 'form_labels.password' )}
									cy="textinput__Login-Password"
									onChange={( value, e ) => handleChange( e )}
									value={values.pw}
									onErrorMessage={errors.pw as string}
									child={
										<EyeSVG onClick={() => setPasswordView( !passwordView )} />
									}
								/>
							</div>
							<div
								style={{
									alignItems: 'flex-end',
									justifyContent: 'flex-end',
									width: '100%',
									display: 'flex'
								}}
							>
								<button
									type="button"
									className="pw-reset-button"
									onClick={() => route.push( '/request-password-reset' )}
									aria-label="reset password"
								>
									{t( 'login.forgot' )}
								</button>
							</div>
							<Button
								actionType="submit"
								onClick={handleSubmit}
								cy="cta__Login-submit"
								label={t( 'login.buttons.login' )}
								disabled={isSubmitting}
							/>
							<Divider label={t( 'basics.or' )} />
							<Button
								type="secondary"
								onClick={() => {
									let address = '/register';
									if ( route.previousPath ) {
										address += `?redirect=${route.previousPath}`;
									}
									route.push( address );
								}}
								label={t( 'login.buttons.register' )}
							/>
						</form>
					)}
				</Formik>
			</Card>
		</Page>
	);
};
