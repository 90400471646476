// eslint-disable jsx-props-no-spreading
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable consistent-return */

import { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import 'react-datetime/css/react-datetime.css';
import { mutate } from 'swr';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ReactComponent as CircleSVG } from '../../assets/vectors/circle.svg';
import { ReactComponent as CheckCircleSVG } from '../../assets/vectors/check-circle.svg';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import {
	getCustomerTypes,
	getOrder,
	ShopPresistor,
	getStripeError
} from './Shop.data';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Alert } from '../../components/Alert';
import { Order } from '../../types/Models';
import { Divider } from '../../components/Divider';
import { formatPrice } from '../../utils/helpers';
import { getSettings, getUser } from '../Settings/Settings.data';
import { Button } from '../../components/Button';
import { HeyServer } from '../../utils/server';

const PAYPAL_OPTIONS = {
	'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
	currency: 'EUR'
};

export type TypePaymentProviders = 'paypal' | 'stripe' | 'skip' | '';

export interface TypePaymentList {
	label: string;
	key: string;
	provider: string;
	isSelected: boolean;
	component?: any;
}

const scrollToOption = ( index: number ): void => {
	const target = document.querySelector( `#payment-options #option-${index}` );
	target?.scrollIntoView( { behavior: 'smooth', block: 'center' } );
}

export default ( props ) => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );
	const stripe = useStripe();
	const elements = useElements();

	// restore local
	const activeOrder = ShopPresistor().getActiveOrder();
	if ( ! activeOrder || ! activeOrder.pk )
		route.push( '/shop' );

	// hooks
	const [ paymentOptions, setPaymentOptions ] = useState<TypePaymentList[] | any>();
	const [ serverError, setServerError ] = useState( '' );
	const [ submitting, setSubmitting ] = useState( false );

	const resetOrder = ( orderId: number | undefined ) => {
		if ( ! orderId )
			return;

		HeyServer.post( `order/${orderId}/reset/` )
			.then( ( response: Order | any ) => {
				switch ( response.payment_status ) {

					case null:
						ShopPresistor().setActiveOrder( response as Order );
						break;

					case 'canceled':
						setServerError(
							'Die Bestellung wurde abgebrochen. Sie werden in Kürze weitergeleitet...'
						);
						setTimeout( () => {
							ShopPresistor().reset();
							route.push( '/' );
						}, 3000 );
						break;

					case 'succeeded':
						resetAndRedirect();
						break;
				}
			} )

			.catch( ( e ) => {
				setServerError( e );
				setSubmitting( false );
			} );
	};

	// SWR calls
	const { data: userData, isLoading: userIsLoading } = getUser( false );
	const { data: settingsData, isLoading: settingsIsLoading } = getSettings( false );
	const { data, isLoading } = getOrder( true, activeOrder.pk as number );
	const { data: customerTypeData, isLoading: customerIsLoading } = getCustomerTypes( false );
	const isFromSofortCallback = 'redirect_status' in route.query || 'payment_intent' in route.query;

	useEffect( () => {
		if ( isFromSofortCallback ) {
			const checker = setInterval( async () => {

				const response = await mutate( [`/order/?id=${activeOrder.pk}`, true] );

				if ( ! response )
					return;

				const { payment_status } = response.results[0];

				if ( payment_status === 'succeeded' ) {
					clearInterval( checker );
					ShopPresistor().reset();
					route.push( '/shop/payment/confirmation', { order: response.results[0] } );
				} else if ( 'requires_action' === payment_status || 'processing' === payment_status ) {
					console.warn( 'Backend has not received positive reply from Stripe webhook yet, re-trying in 1s' );
				} else {
					console.error( `Unexpected reply from payment processor, giving up: ${payment_status}` );
					clearInterval( checker );
					resetOrder( activeOrder.pk );
					setServerError( getStripeError( 'sofort-canceled' ) );
					route.push( '/shop/payment', { order: response.results[0] } );
				}
			}, 1000 );
		}
	} );

	if ( isLoading || userIsLoading || customerIsLoading || settingsIsLoading )
		return <Loader />;

	const order = data.results[0];
	const user = userData.results[0];
	const { sales_tax_percent } = settingsData.results[0];

	// make sure server return has values
	if ( ! data || ! order || order.tickets.length < 1 )
		route.push( '/shop' );

	let orderCoupon: Order | any = null;
	if ( order.coupons_used && order.coupons_used.length > 0 )
		orderCoupon = order.coupons_used[0];

	const usedVouchers = order.incert_vouchers;

	const getActiveProvider = ( bool = false ) => {
		const item: any = paymentOptions.find( ( { isSelected } ) => isSelected );
		if ( item && item.provider ) {
			if ( bool ) {
				return false;
			}

			return item.provider;
		}

		return true;
	};

	const getPaymentSecret = async ( provider: TypePaymentProviders ) => {
		try {
			// Create PaymentIntent as soon as the page loads
			const res: any = await HeyServer.post(
				`order/${order.pk}/payment/`,
				JSON.stringify( { provider } )
			);

			return res.client_secret;
		} catch ( e ) {
			setServerError( e as string );
			resetOrder( order.pk );
		}
	};

	const handleStripe = async ( type: string ) => {
		try {
			setSubmitting( true );

			if ( ! stripe || ! elements )
				return;

			const cardElement: any = elements.getElement( CardElement );
			const stripeSecret: string = await getPaymentSecret( 'stripe' );
			let result: any;

			switch ( type ) {
				case 'stripe-sofort':
					return await stripe.confirmSofortPayment( stripeSecret, {
						payment_method: {
							sofort: {
								country: user.country
							},
							billing_details: {
								name: [ user.first_name || '', user.last_name || '' ].join( ' ' )
							}
						},
						return_url: `${process.env.REACT_APP_INSTANCE_URL}/shop/payment`
					} );

				case 'stripe-cc':
					result = await stripe.confirmCardPayment( stripeSecret, {
						payment_method: {
							card: cardElement,
							billing_details: {
								name: [ user.first_name || '', user.last_name || '' ].join( ' ' )
							}
						}
					} );

					if ( result.paymentIntent?.status === 'succeeded' )
						return resetAndRedirect();

					if ( result.error ) {
						setServerError( getStripeError( result.error.code ) );
						window.scrollTo( { top: 0, left: 0, behavior: 'smooth' } );
						resetOrder( order.pk );
					}

					break;
			}
			
			setSubmitting( false );
		} catch ( error ) {
			setServerError( error as string );
		}
	};

	const resetAndRedirect = () => {
		ShopPresistor().reset();
		route.push( '/shop/payment/confirmation', { order } );
	};

	const handleFreePayment = () => {
		setSubmitting( true );
		HeyServer.post( `order/${order.pk}/payment/`, { payment: 'skip' } )
			.then( () => resetAndRedirect() )
			.catch( ( e ) => {
				setServerError( e );
				setSubmitting( false );
				resetOrder( order.pk );
			} );
	};

	// processes paypal pament
	const handlePaypal = async ( { orderID } ) => {
		if ( ! orderID )
			setServerError( t( 'errors.default_msg' ) );

		return HeyServer.patch( `order/${order.pk}/payment/` )
			.then( () => resetAndRedirect() )
			.catch( ( e ) => {
				setServerError( e );
				resetOrder( order.pk );
			} )
			.finally( () => setSubmitting( false ) );
	};

	const initializePaymentOptions = () => {
		// FIXME: Skip payment provider if env variable is empty; move colour
		// definitions to SCSS or env
		const load: TypePaymentList[] = [
			{
				label: t( 'shop.checkout.payment_methods.creditcard' ),
				key: 'stripe-cc',
				provider: 'stripe',
				isSelected: false,
				component: (
					<CardElement
						options={ {
							style: {
								base: {
									fontSize: '16px',
									color: '#424770',
									'::placeholder': {
										color: '#aab7c4'
									}
								},
								invalid: {
									color: '#9e2146'
								}
							}
						} }
					/>
				)
			},
			{
				label: t( 'shop.checkout.payment_methods.sofort' ),
				key: 'stripe-sofort',
				provider: 'stripe',
				isSelected: false
			},
			{
				label: 'Paypal',
				key: 'paypal',
				provider: 'paypal',
				isSelected: false,
				component: (
					<PayPalScriptProvider options={ PAYPAL_OPTIONS }>
						<PayPalButtons
							style={ { layout: 'horizontal' } }
							createOrder={ ( d, actions ) => getPaymentSecret( 'paypal' ) }
							onApprove={ ( d, actions ) => handlePaypal( d as any ) }
							onCancel={ () => resetOrder( order.pk ) }
						/>
					</PayPalScriptProvider>
				)
			}
		];

		setPaymentOptions( load );
	};

	const getCustomerType = ( id: number ) => {
		const customer = customerTypeData.results.find(
			( { id: cid } ) => cid === Number( id )
		);

		if ( ! customer )
			return 'student';

		return customer.name;
	};

	const prepareTicketList: any = ( all: any ) => {
		const i = order.tickets.findIndex( ( a ) => a && a.voucher );
		const temp = all[i];
		all.splice( i, 1 );
		all.push( temp );
		return all;
	};

	if ( ! paymentOptions || paymentOptions.length < 1 ) {
		initializePaymentOptions();
		return <Loader />;
	}

	return (
		<Page title={ t( 'shop.checkout.title' ) }>
			{ isFromSofortCallback && (
				<div className="PaymentContainerOverlay">
					<h2>{ t( 'shop.checkout.processing' ) }</h2>
				</div>
			) }
			{ serverError && (
				<>
					<br />
					<Alert type="error" show>
						<p>{ serverError }</p>
					</Alert>
				</>
			) }
			<br />
			<div className="PaymentContainer">
				<Card>
					<div className="CartHeader">
						<h1>{ t( 'shop.checkout.overview' ) }</h1>
					</div>

					<div className="CartBody">
						{ order.tickets.map(
							(
								{
									typ,
									contacts,
									price,
									voucher,
									id: ticketId
								}: any | Order,
								id
							) => (
								<div
									key={ ticketId }
									className="CartItem"
									style={ {
										backgroundColor: id % 2 !== 0 ? '#ecf0f1' : 'white'
									} }
								>
									<div className="CartItem__left">
										<h1>{ typ.ticket_type.name }</h1>
										<div className="CartItem__left--container">
											<p>
												{ typ.customer_types.map(
													( { count, customer_type }: any | Order, i ) =>
														`${count} ${getCustomerType( customer_type )}${
															i !== typ.customer_types.length - 1 ? ', ' : ''
														}`
												) }
											</p>
											<span>
												{ [...contacts]
													.reverse()
													.map(
														( { contact }: any | Order, i ) =>
															`${contact.name}${
																i !== contacts.length - 1 ? ', ' : ''
															} `
													) }
											</span>
										</div>
									</div>
									{ ! voucher && (
										<div className="CartItem__right">
											<h1>{ formatPrice( price ) }</h1>
											<h2>{ typ.sales_tax_percent }% USt.</h2>
										</div>
									) }
									{ voucher && (
										<div className="CartItem__right">
											<h3>Gutschein eingelöst</h3>
										</div>
									) }
								</div>
							)
						) }

						{ orderCoupon && (
							<>
								<Divider />

								<div className="CartItem">
									<div className="CartItem__left">
										<h3>{ t( 'shop.checkout.subtotal' ) }</h3>
									</div>
									<div className="CartItem__right">
										<h1>{ formatPrice( order.base_price ) }</h1>
									</div>
								</div>

								<div
									className="CartItem"
									style={{ backgroundColor: '#ecf0f1' }}
								>
									<div className="CartItem__left">
										<p>{ t( 'shop.checkout.used_rebate' ) }</p>
									</div>
									<div
										className="CartItem__right"
										style={ { justifyContent: 'center' } }
									>
										<h2 style={ { margin: 0 } }>
											{ `- ${Number( orderCoupon.typ.value_percent )
														.toFixed( 2 ).replace( '.', ',' )} %` }
										</h2>
									</div>
								</div>
							</>
						) }

						<div className="CartItem">
							<div className="CartItem__left">
									<h3>{ t( 'shop.checkout.sum' ) }</h3>
								
									<p style={ { margin: '6px 0 0' } }>
										{ `inkl. USt. auf ${formatPrice( Number( order.total_price ) -
											Number( order.total_price ) * ( sales_tax_percent / 100 ) ) }` }
									</p>
							</div>
							
							<div className="CartItem__right">
								<h1 className="pricetag">{ formatPrice( order.total_price ) }</h1>
							
								<h2 className="pricetag" style={ { margin: '5px 0 0 0' } }>
									{ `${formatPrice( Number( order.total_price ) * ( sales_tax_percent / 100 ) )}` }
								</h2>
							</div>
						</div>

						{ usedVouchers.length > 0 && (
							<>

								{ usedVouchers.map( ( { value } ) => (
									<div
										className="CartItem"
										style={ { backgroundColor: '#ecf0f1' } }
									>
										<div className="CartItem__left">
											<p>{ t( 'shop.checkout.used_voucher' ) }</p>
										</div>

										<div className="CartItem__right">
											<h2>{ `- ${Number( value ).toFixed( 2 ).replace( '.', ',' )} €` }</h2>
										</div>
									</div>
								) ) }
							</>
						) }

						<div className="CartSummaryToPay">
							<h1>{ t( 'shop.checkout.to_pay' ) }</h1>

							<span className="pricetag">
								{ formatPrice( order.payment_sum ) }
							</span>
						</div>

						<Divider />

						<div className="BillingInfo">
							<h1 style={ { marginTop: 0 } }>{ t( 'shop.checkout.invoice_data' ) }</h1>

							<p>
								{ user.first_name } { user.last_name }
								<br />
								{ user.street_no } 
								<br />
								{ user.zip } { user.city }
								<br /><br />
								{ user.email }
							</p>
						</div>

						<Divider />

						<div className="PaymentOptions">
							<br />

							{ Number( order.payment_sum ) === 0 && (
								<Button
									disabled={ submitting }
									label={ t( 'shop.checkout.payment_methods.order_now' ) }
									onClick={ handleFreePayment }
								/>
							) }

							{ Number( order.payment_sum ) > 0 && (
								<>
									<h1>{ t( 'shop.checkout.select_method' ) }</h1>

									<ul id="payment-options">
										{ paymentOptions.map(
											(
												{
													label,
													component,
													key,
													isSelected,
													provider
												}: TypePaymentList,
												index: number
											) => (
												<Fragment key={ key }>
													<li
														aria-hidden
														id={ `option-${index}` }
														onClick={ () => {
															const options = [ ...paymentOptions ];
															options.forEach( el => el.isSelected = false );
															options[ index ].isSelected = ! options[ index ].isSelected;
															setPaymentOptions( options );
															scrollToOption( index );
														} }
													>
														{ label }
														{ ! isSelected && <CircleSVG /> }
														{ isSelected && <CheckCircleSVG /> }
													</li>

													{ isSelected &&
														provider === 'stripe' &&
														key === 'stripe-sofort' && (
															<p>{ t( 'shop.checkout.sofort_info' ) }</p>
														) }

													{ isSelected && component }

													{ isSelected && provider === 'stripe' && (
														<Button
															disabled={ getActiveProvider( true ) || submitting }
															label={ t( 'buttons.buy_now' ) }
															onClick={ () => handleStripe( key ) }
														/>
													) }

													{ isSelected && <br /> }

													<Divider />
												</Fragment>
											)
										) }
									</ul>
								</>
							) }
						</div>
					</div>
				</Card>
			</div>
		</Page>
	);
};
