import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Pagination } from '../../components/modular/Pagination';
import AllLicenses from '../../assets/licenses.json';
import { VerticalMenu } from '../../components/modular/VerticalMenu';

export default (): JSX.Element => {
	const [page, setPage] = useState( 1 );
	const [t] = useTranslation( 'common' );
	const generateItems = () => {
		const list = Object.entries( AllLicenses );
		const e = page * 10;
		const s = e - 10;
		// 10 per page
		const result = list.slice( s, e ).map( ( item ) => {
			const nameSplit = item[0].split( '@' );
			const name = nameSplit[nameSplit.length - 2];
			const version = nameSplit[nameSplit.length - 1];
			const { licenses, repository }: any = item[1];
			return {
				name,
				licenses,
				repository,
				version
			};
		} );
		return result;
	};
	const len = Object.entries( AllLicenses ).length;
	const perPage = 10;
	const maxPage = Math.ceil( len / perPage );
	const items = generateItems();
	return (
		<Page title={t( 'settings.licences' )}>
			<Card>
				<VerticalMenu type="license-list" items={items} />
			</Card>
			<Card>
				<Pagination
					currentPage={page}
					maxPages={maxPage}
					nextPage={() => setPage( page + 1 )}
					previousPage={() => setPage( page - 1 )}
				/>
			</Card>
		</Page>
	);
};
