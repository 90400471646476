import { FC, ReactNode } from 'react';

export const Button: FC<ButtonProps> = ( {
	size,
	label,
	onClick,
	type,
	actionType = 'button',
	id = '',
	Icon,
	cy = '',
	disabled,
	overrideClassName = null
}: ButtonProps ) => {
	const classNames: string[] = ['Button'];
	type && classNames.push( `Button__${type}` );
	size && classNames.push( `Button__${size}` );
	overrideClassName && classNames.push( overrideClassName );
	const className: string = classNames.join( ' ' );
	return (
		<button
			data-cy={cy}
			id={id}
			onClick={onClick}
			disabled={disabled}
			/* eslint-disable react/button-has-type */
			type={actionType}
			className={className as string}
		>
			{Icon && Icon}
			{label}
		</button>
	);
};

interface ButtonProps {
	/**
	 * Toggle disability
	 */
	disabled?: boolean;
	/**
	 * Class name
	 */
	overrideClassName?: string | null;
	/**
	 * UI style variants for the button
	 */
	type?: 'primary' | 'secondary';
	/**
	 * How large should the button be?
	 */
	size?: 'lg' | 'md' | 'sm';
	/**
	 * Button text label
	 */
	label?: string;
	/**
	 * Vector file as React Element. Ignore its value in Storybook
	 */
	Icon?: ReactNode | null;
	/**
	 * Click callback handler
	 */
	onClick: () => any;
	/**
	 * Button DOM Element 'type' attribute
	 */
	actionType?: 'button' | 'submit' | 'reset';
	id?: string;
	cy?: string;
}
Button.defaultProps = {
	label: '',
	size: 'md',
	id: '',
	disabled: false,
	type: 'primary',
	actionType: 'button',
	Icon: null,
	overrideClassName: null
};
