import React from 'react';
import moment from 'moment';
import 'moment/locale/de';
// components
import ErrorBoundary from './routes/ErrorBoundary';
import Connectivity from './utils/Connectivity';
import Router from './routes/Router';
import { DataProvider } from './utils/server';
// assets
import './i18n';
import './assets/App.scss';
import './assets/tailwind.css';
import '@fontsource/source-sans-pro';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700.css';
import { ShopPresistor } from './features/Shop/Shop.data';

moment.locale( 'de-at' );
ShopPresistor().init();

export type AppPropType = {
	history?: any;
};

export default ( { history }: AppPropType ) => (
	<React.StrictMode>
		<ErrorBoundary>
			<DataProvider>
				<Connectivity>
					<Router history={history} />
				</Connectivity>
			</DataProvider>
		</ErrorBoundary>
	</React.StrictMode>
);
