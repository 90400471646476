import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Input } from '../../components/Input';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { useRouter } from '../../routes/Router.hooks';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { getUser } from './Settings.data';
import { Loader } from '../../components/Loader';

export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	const { data, isLoading } = getUser( true );
	const [serverError, setServerError] = useState( '' );
	const [serverSuccess, setServerSuccess] = useState( '' );

	if ( isLoading ) {
		return <Loader />;
	}

	const required_label = t( 'form_labels.required' );
	const user = data.results[0];
	const { first_name, last_name, email, country, street_no, zip, city } = user;

	const onSubmit = ( values, { setSubmitting } ) =>
		HeyServer.patch( `/user/${user.pk}/`, values )
			.then( () => {
				setServerSuccess( 'Rechnungsdaten aktualisiert!' );
			} )
			.catch( ( e ) => setServerError( e ) )
			.finally( () => setSubmitting( false ) );

	const validate = ( values ) => {
		const errors: any = {};
		if ( !values.email ) {
			errors.email = required_label;
		}
		if ( !values.first_name ) {
			errors.first_name = required_label;
		}
		if ( !values.last_name ) {
			errors.last_name = required_label;
		}
		if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test( values.email ) ) {
			errors.email = 'Invalid email address';
		}
		if ( values.country !== 'AT' ) {
			!values.street_no && ( errors.street_no = required_label );
			!values.zip && ( errors.zip = required_label );
			!values.city && ( errors.city = required_label );
		}
		return errors;
	};

	return (
		<Page title={t( 'settings.billing' )}>
			{serverError && (
				<Alert type="error" show>
					<p>{serverError}</p>
				</Alert>
			)}
			{serverSuccess && (
				<Alert type="success" show>
					<p>{serverSuccess}</p>
				</Alert>
			)}
			<Card>
				<Formik
					initialValues={{
						first_name,
						last_name,
						email,
						country,
						street_no,
						zip,
						city
					}}
					validate={validate}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={onSubmit}
				>
					{( { values, errors, handleChange, handleSubmit, isSubmitting } ) => (
						<form onSubmit={handleSubmit}>
							<div className="row">
								<Input
									name="first_name"
									type="with-label"
									label={t( 'form_labels.name' )}
									onChange={( value, e ) => handleChange( e )}
									value={values.first_name}
									onErrorMessage={errors.first_name as string}
								/>
								<Input
									name="last_name"
									type="with-label"
									label={t( 'form_labels.surname' )}
									onChange={( value, e ) => handleChange( e )}
									value={values.last_name}
									onErrorMessage={errors.last_name as string}
								/>
							</div>
							<Input
								inputType="email"
								name="email"
								type="with-label"
								label="e-mail"
								onChange={( value, e ) => handleChange( e )}
								value={values.email}
								onErrorMessage={errors.email as string}
							/>

							<Input
								inputType="select"
								name="country"
								type="with-label"
								label={t( 'form_labels.countries.title' )}
								selectValues={[
									{
										optionLabel: 'AT',
										optionValue: t( 'form_labels.countries.at' )
									},
									{
										optionLabel: 'DE',
										optionValue: t( 'form_labels.countries.de' )
									},
									{
										optionLabel: 'IT',
										optionValue: t( 'form_labels.countries.it' )
									},
									{
										optionLabel: 'SI',
										optionValue: t( 'form_labels.countries.si' )
									}
								]}
								onChange={( value, e ) => {
									handleChange( e );
								}}
								value={values.country}
								onErrorMessage={errors.country as string}
							/>
							<Input
								name="street_no"
								type="with-label"
								label={t( 'form_labels.street' )}
								onChange={( value, e ) => handleChange( e )}
								value={values.street_no}
								onErrorMessage={errors.street_no as string}
							/>
							<div className="row">
								<Input
									name="zip"
									type="with-label"
									label={t( 'form_labels.zip' )}
									onChange={( value, e ) => handleChange( e )}
									value={values.zip}
									onErrorMessage={errors.zip as string}
								/>
								<Input
									name="city"
									type="with-label"
									label={t( 'form_labels.place' )}
									onChange={( value, e ) => handleChange( e )}
									value={values.city}
									onErrorMessage={errors.city as string}
								/>
							</div>
							<Button
								onClick={handleSubmit}
								label={t( 'buttons.save' )}
								disabled={isSubmitting}
							/>
						</form>
					)}
				</Formik>
			</Card>
		</Page>
	);
};
