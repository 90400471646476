import { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { useRouter } from '../../routes/Router.hooks';
import { ReactComponent as EyeSVG } from '../../assets/vectors/eye.svg';
import { PasswordStrengthMeter } from '../../components/modular/PasswordStrengthMeter';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { isLoggedIn } from '../../utils/helpers';

export default (): any => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );

	if ( isLoggedIn() )
		route.push( '/' );

	const { query }: any = route;
	const [ serverSuccess, setServerSuccess ] = useState( '' );
	const [ serverError, setServerError ] = useState( '' );
	const [ passwordView, setPasswordView ] = useState( true );

	const onSubmit = ( values, { setSubmitting } ) => {
		HeyServer.post( '/pw-reset-confirm', {
			token: query.token,
			new_pw: values.password
		} )

		.then( () => {
			setServerSuccess( t( 'settings.change_pw.success' ) );
			setTimeout( () => route.push( '/login' ), 2000 );
		} )

		.catch( ( e ) => setServerError( e ) )
		.finally( () => setSubmitting( false ) );
	};

	const validate = ( values ) => {
		const errors: any = {};

		if ( ! values.password ) {
			errors.password = t( 'errors.form.empty', {
				value: t( 'form_labels.old_pw' )
			} );
		}

		if ( ! values.repeat_password )
			errors.repeat_password = t( 'errors.form.repeat' );

		if ( ! values.isPasswordStrong )
			errors.password = t( 'errors.form.password' );

		if ( values.password !== values.repeat_password )
			errors.repeat_password = t( 'errors.form.no_match' );

		return errors;
	};

	return (
		<Page title={ t( 'settings.change_pw.title' ) }>
			{ serverError && (
				<Alert type="error" show>
					<p>{ serverError }</p>
				</Alert>
			) }
			{ serverSuccess && (
				<Alert type="success" show>
					<p>{ serverSuccess }</p>
				</Alert>
			) }
			<Card>
				<Formik
					initialValues={ {
						password: '',
						repeat_password: ''
					} }
					validate={ validate }
					validateOnChange={ false }
					validateOnBlur={ false }
					onSubmit={ onSubmit }
				>
					{ ( {
						values,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting,
						setFieldValue
					} ) => (
						<form onSubmit={ handleSubmit }>
							<span>Hier kannst du ein neues Passwort eingeben.</span>

							<br />

							<div className="__Input-password">
								<Input
									inputType={ passwordView ? 'password' : 'text' }
									name="password"
									type="with-label"
									label={ t( 'settings.change_pw.new_pw' ) }
									onChange={ ( value, e ) => handleChange( e ) }
									value={ values.password }
									onErrorMessage={ errors.password as string }
									child={
										<EyeSVG onClick={ () => setPasswordView( !passwordView ) } />
									}
								/>
							</div>
							<PasswordStrengthMeter
								email=""
								password={ values.password }
								isAcceptable={ ( s ) => setFieldValue( 'isPasswordStrong', s ) }
							/>

							<div className="__Input-password">
								<Input
									inputType={ passwordView ? 'password' : 'text' }
									name="repeat_password"
									type="with-label"
									label={ t( 'settings.change_pw.repeat' ) }
									onChange={ ( value, e ) => handleChange( e ) }
									value={ values.repeat_password }
									onErrorMessage={ errors.repeat_password as string }
									child={
										<EyeSVG onClick={ () => setPasswordView( ! passwordView ) } />
									}
								/>
							</div>

							<Button
								actionType="submit"
								onClick={ handleSubmit }
								label={ t( 'settings.change_pw.button' ) }
								disabled={ isSubmitting }
							/>
						</form>
					) }
				</Formik>
			</Card>
		</Page>
	);
};
