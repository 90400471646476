// eslint-disable jsx-props-no-spreading
/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import 'react-datetime/css/react-datetime.css';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import {
	getTicketTypes,
	getCustomerTypes,
	ShopPresistor,
	getPrice
} from './Shop.data';
import { Loader } from '../../components/Loader';
import { Stepper, StepperItemsType } from '../../components/modular/Stepper';
import { Card } from '../../components/Card';
import { Counter } from '../../components/modular/Counter';
import { formatPrice } from '../../utils/helpers';
import { Alert } from '../../components/Alert';
import {
	CartSummary,
	TicketDataType
} from '../../components/modular/CartSummary';
import { CustomerType } from '../../types/Models';

export interface TypePaxSelectItem extends CustomerType {
	base_price: string;
	counterValue: number;
	customer_type_id: number;
	is_disabled: boolean;
}
export default ( props ): JSX.Element => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	// redirect back to first step of params are corrupt
	const shopState = ShopPresistor().getSelections();
	if ( !shopState.ttid ) {
		route.push( '/shop' );
	}
	const [localLoaded, setLocalLoaded] = useState( false );
	// LocalStorage maps
	const [count, setCount] = useState( 0 );
	const [paxTypesList, setPaxList] = useState<TypePaxSelectItem[]>( [] );
	const [paxInfoList, setPaxInfoList] = useState( shopState.pax_infos ); // fix for sync issues
	// server error
	const [serverError, setServerError] = useState( '' );
	// when new price is being retrieved from the server
	const [submitting, setSubmitting] = useState( false );
	// summary box info
	const [summary, setSummary] = useState<TicketDataType>( {
		name: '',
		count: 0,
		price: 0
	} );
	const stepperItems: StepperItemsType[] = [
		{
			title: t( 'progress.date' ),
			onClick: () => route.push( '/shop/select-date' ),
			filled: true,
			active: true
		},
		{
			title: t( 'progress.amount' ),
			filled: false,
			active: true
		},
		{
			title: t( 'progress.contacts' ),
			filled: false,
			active: false
		}
	];
	// SWR calls
	const { data, isLoading } = getTicketTypes( false );
	const { data: customerTypeData, isLoading: customerIsLoading } =
		getCustomerTypes( false );
	useEffect( () => {
		// update local storage as well
		const payload = {
			summary,
			pax_type_count: paxTypesList,
			pax_infos: paxInfoList
		};
		ShopPresistor().setSelections( payload as any );
	}, [summary, paxTypesList, paxInfoList] );
	// dont proceed until loaded
	if ( isLoading || customerIsLoading ) {
		return <Loader />;
	}
	const ticketType = data.results.find(
		( { id } ) => id === Number( shopState.ttid )
	);

	if (
		ticketType.is_flexible ||
		!ticketType.allow_presale ||
		ticketType.activation_type === 2
	) {
		stepperItems[0].filled = false;
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		stepperItems[0].onClick = () => {};
	}

	const generatePaxCounterList = (): TypePaxSelectItem[] => {
		// generate a list of customers for this ticket
		const paxCounterList: any = [];
		// get customer types...
		customerTypeData.results.forEach( ( customerType ) => {
			// get supported customer tyoes of the active ticket...
			ticketType.supported_types.forEach(
				( { base_price, customer_types, show_in_list } ) => {
					// extract the customer info for each given id
					show_in_list &&
						customer_types.forEach( ( { customer_type: id } ) => {
							if ( customerType.id === id ) {
								const load = {
									...customerType,
									base_price,
									customer_type_id: id,
									counterValue: 0
								};
								delete load.id;
								paxCounterList.push( load );
							}
						} );
				}
			);
		} );
		return paxCounterList;
	};
	// loads a params from the URL and adds pax counts and updates summary with local update
	const loadIncertPackage = () => {
		const { ttid }: any = ShopPresistor().getSelections();
		ShopPresistor().resetState( ttid );
		const { pax_override } = route.query;
		const decoded = JSON.parse( decodeURIComponent( pax_override ) );
		const payload: any = {
			summary: null,
			pax_type_count: generatePaxCounterList(),
			pax_infos: []
		};
		// build pax types and info list
		decoded.forEach( ( ttcp ) => {
			const index = payload.pax_type_count.findIndex(
				( { customer_type_id } ) => ttcp.customer_type === customer_type_id
			);
			payload.pax_type_count[index].counterValue = ttcp.count;
			let i = 1;
			while ( i <= ttcp.count ) {
				payload.pax_infos.push( {
					customer_type_info: payload.pax_type_count[index],
					formEntries: {
						image: '',
						name: '',
						birthday: ''
					},
					formErrors: {
						image: '',
						name: '',
						birthday: ''
					}
				} );
				i += 1;
			}
		} );
		// update summary
		return getPrice( payload.pax_type_count, ticketType.id ).then(
			( price: any ) => {
				const newSummary = {
					price,
					name: ticketType.name,
					count: payload.pax_type_count
						.map( ( { counterValue } ) => counterValue )
						.reduce( ( a, b ) => a + b )
				};
				payload.summary = newSummary;
				ShopPresistor().setSelections( payload );
				route.push( '/shop/pax-info' );
			}
		);
	};

	// called when user changes counter value, updates local then notifies server then updates the summary
	const counterUpdate = ( current_count, prev_count, index ) => {
		// this will prevent user changing the counter too fast
		if ( submitting ) {
			return;
		}
		setSubmitting( true );
		// first update counter
		const pax_types: any = [...paxTypesList];
		pax_types[index].counterValue = current_count;
		const total_counts = paxTypesList
			.filter( ( { is_disabled } ) => !is_disabled )
			.reduce( ( a, b ) => a + b.counterValue, 0 );
		setPaxList( pax_types );
		// prepare customer info field
		const removePax = prev_count > current_count;
		const addPax = prev_count < current_count;
		const currentPaxInfos: any = [...( paxInfoList as any )];
		const type_id_in_list = pax_types[index].customer_type_id;

		// if counter is reduced
		if ( removePax ) {
			setQuota( 'add' );
			currentPaxInfos.every( ( { customer_type_info }, i ) => {
				if ( customer_type_info.id === type_id_in_list ) {
					currentPaxInfos.splice( i, 1 );
					return false;
				}
				return true; // possible break! after TS change
			} );
		}
		// if counter has increased
		if ( addPax ) {
			setQuota( 'sub' );
			currentPaxInfos.push( {
				customer_type_info: customerTypeData.results.find(
					( { id } ) => Number( pax_types[index].customer_type_id ) === id
				),
				formEntries: {
					image: '',
					name: '',
					birthday: ''
				},
				formErrors: {
					image: '',
					name: '',
					birthday: ''
				}
			} );
		}

		setPaxInfoList( currentPaxInfos );
		// update summary box
		updateSummary( pax_types );
	};

	// update remaining_quota logic
	const setQuota = ( mode ) => {
		if ( !currentDate ) {
			return;
		}
		switch ( mode ) {
			case 'add':
				currentDate.remaining_quota += 1;
				setCount( count - 1 );
				break;
			case 'sub':
				if ( currentDate.remaining_quota !== 0 ) {
					currentDate.remaining_quota -= 1;
					setCount( count + 1 );
				}
				break;
		}
	};
	const updateSummary = ( paxTypes ) => {
		let totalAddedPax = 0;
		paxTypes.forEach( ( { counterValue } ) => {
			totalAddedPax += counterValue;
		} );
		// if no pax are added we simply just reset the summary
		if ( totalAddedPax === 0 ) {
			setSubmitting( false );
			const newSummary = {
				price: 0,
				name: '',
				count: 0
			};
			return setSummary( newSummary );
		}
		// else get the summary price from the backend
		return getPrice( paxTypes, ticketType.id )
			.then( ( price: any ) => {
				const newSummary = {
					price,
					name: ticketType.name,
					count: paxTypes
						.map( ( { counterValue } ) => counterValue )
						.reduce( ( a, b ) => a + b )
				};
				setSummary( newSummary );
			} )
			.catch( ( e ) => setServerError( e ) )
			.finally( () => setSubmitting( false ) );
	};
	const loadFromLocalStorage = () => {
		setPaxList( shopState.pax_type_count as any );
		setSummary( shopState.summary as any );
		setLocalLoaded( true );
	};
	const init = ( skipLocalLoad = false ) => {
		// load state from localStorage
		if (
			!skipLocalLoad &&
			!localLoaded &&
			( shopState.pax_type_count as any ).length > 0
		) {
			return loadFromLocalStorage();
		}
		return setPaxList( generatePaxCounterList() );
	};
	// if the url param has incert override, go for loading the package
	if ( route.query.pax_override && !submitting ) {
		setSubmitting( true );
		loadIncertPackage();
	}
	// if (submitting) {
	//   return <Loader />;
	// }
	if ( paxTypesList.length < 1 ) {
		init();
		return <Loader />;
	}
	const currentDate = ticketType.booked_dates.find(
		( { date } ) => date === shopState.date
	);

	const checkQuota = () => {
		if ( count === 0 && summary.count === currentDate.remaining_quota ) {
			currentDate.remaining_quota = 0;
		}
		return currentDate.remaining_quota;
	};

	return (
		<Page title={t( 'shop.amount.title' )}>
			<Stepper items={stepperItems} />
			{serverError && (
				<>
					<br />
					<Alert type="error" show>
						<p>{serverError}</p>
					</Alert>
				</>
			)}
			<br />
			<div className="PaxCounterContainer">
				{paxTypesList.map(
					( { name, description, base_price, counterValue }, index ) => (
						<Card>
							<div>
								<h3>{name}</h3>
								<p>{description}</p>
								<span>{formatPrice( base_price )}</span>
							</div>
							<Counter
								upperLimit={currentDate ? checkQuota() : false}
								value={counterValue}
								onNewValue={( value, oldValue ) =>
									counterUpdate( value, oldValue, index )
								}
							/>
						</Card>
					)
				)}
			</div>
			<CartSummary
				disabled={summary.count === 0}
				btnLabel={t( 'buttons.continue' )}
				ticketData={summary}
				onClick={() => route.push( '/shop/pax-info' )}
			/>
		</Page>
	);
};
