// eslint-disable jsx-props-no-spreading
/* eslint-disable prefer-destructuring */
import { useState, Fragment, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { mutate } from 'swr';
import 'react-datetime/css/react-datetime.css';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { ReactComponent as ShareSVG } from '../../assets/vectors/share.svg';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { Order } from '../../types/Models';
import { Divider } from '../../components/Divider';
import { HeyServer } from '../../utils/server';
import { shareImage } from '../../utils/helpers';
import {
	getCustomerTypes,
	getOrder,
	getTickets,
} from './Shop.data';

export default (): any => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );
	const [ serverError, setServerError ] = useState( '' );

	if ( ! route.location.state || ! route.location.state.order ) {
		route.push( '/shop' );
		return <Loader />;
	}

	// Cache tickets after purchase
	getTickets( true );

	const activeOrder = route.location.state.order;

	// clears the route state on page leave or reload
	window.history.replaceState( {}, document.title );

	// SWR calls
	const { data, isLoading } = getOrder( true, activeOrder.pk as number );
	const { data: customerTypeData, isLoading: customerIsLoading } =
		getCustomerTypes( false );

	// dont proceed until loaded
	if ( isLoading || customerIsLoading )
		return <Loader />;

	if ( ! data ) {
		route.push( '/shop' );
		return <Loader />;
	}

	const order = data.results[0];

	if ( ! data || ! order )
		route.push( '/shop' );

	const getTicketName = ( customerTypes: any ) => {

		if ( customerTypes.length < 2 ) {
			const target = customerTypes[0].customer_type;
			const currentType = customerTypeData.results.find( ( { id } ) => id === target );

			if ( ! currentType )
				return 'student';

			return currentType.name;
		}

		const typeNames = customerTypes.map( ( { count, customer_type } ) => {
			const name = customerTypeData.results.find( ( { id } ) => id === customer_type ).name;

			return `${count} ${name}`;
		} );

		return typeNames.join( ', ' );
	};

	const downloadTicket = ( ticketId: number, contactName: string ) => {
		HeyServer.get( `ticket/${ticketId}/qr_share/`, {
			responseType: 'arraybuffer'
		} )
			.then( ( res: any ) => {
				shareImage( new Blob( [res] ), contactName );
			} )
			.catch( ( e ) => {
				setServerError( e );
			} );
	};

	const flexibleInOrder = order.tickets.some(
		( ticket: any ) => ticket.typ.ticket_type.is_flexible
	);

	return (
		<Page title={ t( 'shop.confirmation.title' ) } noBackButton>
			<div className="MyTicketsContainer">
				{ serverError && (
					<>
						<br />
						<Alert type="error" show>
							<p>{ serverError }</p>
						</Alert>
					</>
				) }
				<>
					<Alert type="success" show>
						<p>{ t( 'shop.confirmation.thanks' ) }</p>
					</Alert>

					{ flexibleInOrder && (
						<Alert type="warning" show>
							<p>{ t( 'shop.confirmation.warning' ) }</p>
						</Alert>
					) }
				</>
				<Card>
					<div className="CartBody">
						{ order.tickets.map(
							( {
								typ,
								activations,
								contacts,
								id: ticketId,
								valid_from,
								valid_until
							}: any | Order ) => {
								const { is_flexible, max_uses } = typ.ticket_type;

								const canBeActivated = Boolean(
									is_flexible && activations.length < Number( max_uses )
								);

								const isActive = Boolean(
									moment().isBetween( valid_from, valid_until )
								);

								return contacts.map( ( { contact }: any | Order ) => (
									<Fragment key={ contact.id }>
										<div
											className={ `CartItem CartItem__${
												canBeActivated || ! isActive
													? 'inactive'
													: 'active'
											}` }
										>
											<div
												className={ `CartItem__left${
													canBeActivated && ! isActive ? ' inactive' : ''
												}` }
											>
												<h1>{ typ.ticket_type.name }</h1>

												<p>{ getTicketName( typ.customer_types ) }</p>

												<span>{ contact.name }</span>
											</div>
											{ ! canBeActivated && isActive && (
												<div className="CartItem__center">
													<div className="active-info" aria-hidden>
														<h1>{ isActive ? t( 'basics.active' ) : '' }</h1>

														<div>
															<>
																<p>
																	{ `${t( 'basics.on' )} ${moment
																		.parseZone( valid_until )
																		.local( true )
																		.format( 'DD.MM.YYYY' )}` }
																</p>
																<p>
																	{ `${t( 'basics.until' )} ${moment
																		.parseZone( valid_until )
																		.local( true )
																		.format( 'HH:mm [Uhr]' )}
																	` }
																</p>
															</>
														</div>
													</div>
												</div>
											) }

											<div className="CartItem__right">
												<Button
													onClick={ () =>
														downloadTicket( ticketId, contact.name )
													}
													Icon={ <ShareSVG /> }
												/>
											</div>
										</div>
										<Divider />
									</Fragment>
								) );
							}
						) }
					</div>

					<div className="CartFooter">
						<Button
							label={ t( 'buttons.to_tickets' ) }
							onClick={ () => {
								mutate( '/ticket/?ordering=valid_from&page=1' );
								route.push( '/tickets/' );
							} }
						/>
						<b>{ t( 'shop.confirmation.wrong_bought.title' ) }</b>

						<p>
							{ t( 'basics.you_can' ) }{ ' ' }
							<a href="/settings/orders" aria-label="go to my orders">
								{ t( 'shop.confirmation.wrong_bought.desc' ) }
							</a>
						</p>
					</div>
				</Card>
			</div>
		</Page>
	);
};
