/* eslint-disable prefer-destructuring */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { getCustomerTypes, getTicketTypes, ShopPresistor } from './Shop.data';
import { Loader } from '../../components/Loader';
import { TagList, TypeTagItem } from '../../components/modular/TagList';
import { Card } from '../../components/Card';
import { Divider } from '../../components/Divider';
import { ReactComponent as InfoSVG } from '../../assets/vectors/info.svg';
import { formatPrice } from '../../utils/helpers';
import { TicketType } from '../../types/Models';

type TicketTypeCategory = { category: string; description: string };
interface CustomTicketType extends TicketType {
	price_from: string;
}
type TicketTypeCategoryWithTickets = {
	category: string;
	description: string;
	tickets: CustomTicketType[];
};
// generate a list of categories with description
export const getCategories = ( serverList: any ): TicketTypeCategory[] => {
	const categoryList: TicketTypeCategory[] = [];
	serverList.forEach( ( { category } ) => {
		const newEntry: TicketTypeCategory = {
			category: category.name,
			description: category.description
		};
		!_.find( categoryList, newEntry ) && categoryList.push( newEntry );
	} );
	return categoryList;
};
// generate a unique list of tags from categories for the TagList component
export const getTags = ( categories ): TypeTagItem[] => {
	const newTags = [...categories].map( ( { category } ) => ( {
		label: category,
		active: false
	} ) );
	return newTags;
};
// prepare a list for render
// generates a list of categories with description and tickets
export const formatList = (
	serverList: any,
	tags: TypeTagItem[],
	categories: TicketTypeCategory[]
): TicketTypeCategoryWithTickets[] => {
	// filter tag check only if not all the labels are inactive
	const canCheckTagFilter = tags.find( ( { active } ) => active );
	const list: TicketTypeCategoryWithTickets[] = [];
	const categoryList = categories;
	// map tickets to their category
	categoryList?.forEach( ( { category, description } ) => {
		const tickets: CustomTicketType[] = [];
		serverList.forEach(
			( {
				category: item_category,
				name,
				id,
				supported_types,
				is_flexible,
				allow_presale,
				activation_type,
				show_in_shop,
				quota_enabled,
				quota_today
			} ) => {
				if ( category === item_category.name && show_in_shop ) {
					const prices = supported_types.map( ( { base_price } ) => base_price );
					const price_from = String( Math.min( ...prices ) );
					tickets.push( {
						id,
						name,
						price_from,
						allow_presale,
						is_flexible,
						activation_type,
						show_in_shop,
						quota_enabled,
						quota_today
					} );
				}
			}
		);
		list.push( { category, description, tickets } );
	} );
	// only add active categories
	if ( canCheckTagFilter ) {
		const updatedList: TicketTypeCategoryWithTickets[] = [];
		tags?.forEach( ( { label, active } ) => {
			for ( let index = 0; index < list.length; index += 1 ) {
				if ( label === list[index].category && active ) {
					updatedList.push( list[index] );
				}
			}
		} );
		return updatedList;
	}
	return list;
};
// When user clicks on one of the tickets
export const onTicketSelect = (
	ticket_type_id,
	is_flexible,
	allow_presale,
	activation_type,
	customer_types = null
): string => {
	// full reset of shop state
	ShopPresistor().resetState( ticket_type_id );

	// if incert package has customer types loaded
	const customer_data = encodeURIComponent( JSON.stringify( customer_types ) );
	let page_name = 'select-date';
	let extra_params = '';

	// Set correct date for ticket type and skip datepicker for following types.
	if ( is_flexible || !allow_presale || activation_type === 2 ) {
		if ( activation_type === 2 ) {
			ShopPresistor().setSelections( {
				date: moment().format( 'YYYY-MM-DD' )
			} );
		} else {
			ShopPresistor().setSelections( {
				date: null,
			} );
		}

		page_name = 'select-pax';
	}

	if ( customer_types )
		extra_params = `?pax_override=${customer_data}`;

	return `/shop/${page_name}${extra_params}`;
};
// component
export default (): JSX.Element => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	const [tags, setTags] = useState<TypeTagItem[] | any>();
	const [categories, setCategories] = useState<TicketTypeCategory[] | any>();
	const { data, isLoading } = getTicketTypes( true );
	if ( isLoading ) {
		return <Loader />;
	}
	const ticketTypes = data.results;
	// Incert Package
	const loadIncertPackage = () => {
		const { status, payload } = ShopPresistor().getFlags().incert_package;
		const { supported_type } = JSON.parse( payload );
		if ( !status || !supported_type ) {
			ShopPresistor().flagOff( 'incert_package' );
			return;
		}
		let ticketType;
		let customerConfig;
		ticketTypes.forEach( ( { supported_types, id, is_flexible } ) => {
			if ( customerConfig ) return;
			customerConfig = supported_types.find(
				( { id: __id, is_disabled } ) =>
					__id === Number( supported_type ) && !is_disabled // possible TS break
			);
			if ( customerConfig ) ticketType = { id, is_flexible };
		} );
		if ( !customerConfig ) {
			return;
		}
		// navigate
		route.push(
			onTicketSelect(
				ticketType.id,
				ticketType.allow_presale,
				ticketType.is_flexible,
				ticketType.activation_type,
				customerConfig.customer_types
			)
		);
	};
	const renderInfoBox = ( description ) =>
		confirmAlert( {
			message: description,
			buttons: [
				{
					label: 'schließen',
					onClick: () => true
				}
			]
		} );
	const renderQuotaInfo = ( quotaCount: number, isToday = false ) => (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'row',
				justifyContent: 'space-between',
				marginTop: 10,
				position: 'absolute',
				bottom: 10,
				left: 10
			}}
		>
			<div style={{ fontSize: 13, color: '#7D8385' }}>
				{isToday && 'Heute'} noch {quotaCount} übrig!
			</div>
		</div>
	);

	// first make sure categories are sorted
	if ( !categories ) {
		setCategories( getCategories( ticketTypes ) );
		return <Loader />;
	}
	// make sure tags are available
	if ( !tags ) {
		setTags( getTags( categories ) );
		return <Loader />;
	}

	loadIncertPackage();

	return (
		<Page title={t( 'shop.start.title' )}>
			<h1>{t( 'shop.start.dsc' )}</h1>
			<TagList type="multi" onChange={setTags} tags={tags} />
			{formatList( ticketTypes, tags, categories ).map(
				( { category, description, tickets }, i ) =>
					tickets.length !== 0 && (
						<div className="TicketTypeContainer">
							<div className="TicketTypeHeader">
								<h2>{category}</h2>
								{description && (
									<InfoSVG
										data-cy={`svg__TicketType-Modal-${i + 1}`}
										onClick={() => renderInfoBox( description )}
									/>
								)}
							</div>
							<div className="TicketTypeChild">
								{tickets.map(
									( {
										id: ticket_type_id,
										name,
										price_from,
										is_flexible,
										allow_presale,
										activation_type,
										show_in_shop,
										quota_enabled,
										quota_today
									} ) =>
										show_in_shop && (
											<Card
												key={name}
												onClick={() =>
													route.push(
														onTicketSelect(
															ticket_type_id,
															is_flexible,
															allow_presale,
															activation_type
														)
													)
												}
											>
												<p className="TicketTypeName">{name}</p>
												<Divider />
												{quota_enabled &&
													renderQuotaInfo( Number( quota_today ), true )}
												<p className="TicketTypePrice">
													ab
													<span> {formatPrice( price_from )} </span>
												</p>
											</Card>
										)
								)}
							</div>
						</div>
					)
			)}
		</Page>
	);
};
