import { RouteObject } from '../../types/Types';
import StartPage from './Start.page';
import PriceList from './PriceList.page';

export default [
	{
		name: 'start_page',
		link: '/',
		availableOffline: true,
		component: <StartPage />,
		isProtected: false
	},
	{
		name: 'price_list_page',
		link: '/shop/list',
		availableOffline: true,
		component: <PriceList />,
		isProtected: false
	}
] as unknown as RouteObject[];
