import { FC } from 'react';

export const Divider: FC<DividerProps> = ( { label }: DividerProps ) => {
	const classNames: string[] = ['Divider'];
	const className: string = classNames.join( ' ' );
	return <div className={className as string}>{label && <p>{label}</p>}</div>;
};

export interface DividerProps {
	/**
	 * The text in the divider
	 */
	label?: string;
}
Divider.defaultProps = {};
