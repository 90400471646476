import { useState, useEffect } from 'react';
import {
	Accordion,
	AccordionItem,
	AccordionItemState,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion';
import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Input } from '../../components/Input';
import { getFaq } from './Settings.data';
import { FAQ } from '../../types/Models';
import { ReactComponent as SearchSVG } from '../../assets/vectors/search.svg';
import { ReactComponent as CloseSVG } from '../../assets/vectors/x.svg';
import { ReactComponent as PlusSVG } from '../../assets/vectors/plus.svg';
import { ReactComponent as MinusSVG } from '../../assets/vectors/minus.svg';

const RenderHelpPage = (): JSX.Element => {
	const { data, isLoading } = getFaq( false );
	const [ t ] = useTranslation( 'common' );
	const [ questions, setQuestions ]: [ FAQ[], any ] = useState( [] );
	const [ searchText, setSearchText ] = useState( '' );

	const filterTextSearch = () => {
		if ( ! searchText )
			return data.results;

		const options = {
			includeScore: true,
			keys: ['question', 'answer']
		};

		const fuse = new Fuse( data.results, options );
		const result = fuse.search( searchText ).map( ( { item } ) => ( {
			question: item.question,
			answer: item.answer
		} ) );

		return result;
	};

	useEffect( () => { ! isLoading && setQuestions( filterTextSearch() ); }, [searchText] );

	if ( isLoading )
		return <Loader />;

	if ( questions.length === 0 )
		setQuestions( data.results );

	return (
		<Page title={ t( 'settings.help' ) }>
			<Card>
				<div
					style={ {
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						width: '100%'
					} }
				>
					<div style={ { padding: '13px' } }>
						<h5
							style={ {
								margin: '0 0 5px 0',
								textTransform: 'uppercase'
							} }
						>
							hilfe durchsuchen
						</h5>
						<div style={ { position: 'relative' } }>
							<Input
								autoComplete="off"
								name="search-input"
								value={searchText}
								placeholder="Suchbegriff eingeben"
								onChange={( v ) => setSearchText( v )}
							/>

							{ searchText && (
								<div
									aria-hidden
									className="search-close-icon"
									onClick={ () => setSearchText( '' ) }
								>
									<CloseSVG />
								</div>
							) }
							{ ! searchText && (
								<div
									style={ {
										position: 'absolute',
										top: '8px',
										right: '15px',
										color: '#7992ab'
									} }
								>
									<SearchSVG width="20" />
								</div>
							) }
						</div>
					</div>

					<Accordion allowZeroExpanded onChange={ ( v ) => true }>
						{ questions.map( ( { question, answer } ) => (
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="help-accordion-heading">{ question }</div>

										<AccordionItemState>
											{ ( { expanded } ) =>
												expanded ? (
													<div className="accordion-item">
														<MinusSVG />
													</div>
												) : (
													<div className="accordion-item">
														<PlusSVG width="20" />
													</div>
												)
											}
										</AccordionItemState>
									</AccordionItemButton>
								</AccordionItemHeading>

								<AccordionItemPanel className="help-accordion-panel">
									<p dangerouslySetInnerHTML={ { __html: answer } } />
								</AccordionItemPanel>
							</AccordionItem>
						) ) }
					</Accordion>
				</div>
			</Card>
		</Page>
	);
};

export default RenderHelpPage;
