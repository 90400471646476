import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { mutate } from 'swr';
import { Formik } from 'formik';
import { Input } from '../../components/Input';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { useRouter } from '../../routes/Router.hooks';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { getSettings, getUser } from '../Settings/Settings.data';
import { Loader } from '../../components/Loader';
import { CheckBox } from '../../components/CheckBox';
import { ShopPresistor } from './Shop.data';

export default (): any => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );
	const { data, isLoading } = getUser( true );
	const activeOrder: any = ShopPresistor().getActiveOrder();
	const [ serverError, setServerError ] = useState( '' );
	const [ serverSuccess, setServerSuccess ] = useState( '' );
	const { data: settingsData, isLoading: settingsLoading } = getSettings( true );

	if ( ! data || isLoading || settingsLoading )
		return <Loader />;

	if ( ! data.results[0].is_registered ) {
		mutate( ['/user/', true] );
		return <Loader />;
	}

	const user = data.results[0];
	const { terms_of_service_date, privacy_policy_date } = settingsData.results[0];
	const orderRequiresAddress = Number( activeOrder.total_price ) > 400;

	const repromptTos = moment( user.tos_accepted ).isBefore( terms_of_service_date );
	const repromptPp = moment( user.pp_accepted ).isBefore( privacy_policy_date );
	const anyReprompt = repromptTos || repromptPp;

	const {
		first_name,
		last_name,
		email,
		country,
		street_no,
		zip,
		city,
		pp_accepted,
		tos_accepted
	} = user;

	const onSubmit = ( values, { setSubmitting } ) =>
		HeyServer.patch( `/user/${user.pk}/`, values )
			.then( () => route.push( '/shop/payment' ) )
			.catch( ( e ) => setServerError( e ) )
			.finally( () => setSubmitting( false ) );

	const validate = ( values ) => {
		const errors: any = {};
		if ( ! values.email ) {
			errors.email = t( 'errors.form.empty', {
				value: 'E-Mail'
			} );
		}

		if ( ! values.first_name ) {
			errors.first_name = t( 'errors.form.empty', {
				value: t( 'form_labels.name' )
			} );
		}

		if ( ! values.last_name ) {
			errors.last_name = t( 'errors.form.empty', {
				value: t( 'form_labels.surname' )
			} );
		}

		if ( anyReprompt ) {
			if ( ! values.pp_accepted && repromptPp ) {
				errors.pp_accepted = t( 'form_labels.required' );
			}

			if ( ! values.tos_accepted && repromptTos ) {
				errors.tos_accepted = t( 'form_labels.required' );
			}
		}

		if ( ! /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test( values.email ) ) {
			errors.email = t( 'erros.form.email' );
		}

		if ( values.country !== 'AT' || orderRequiresAddress ) {
			! values.street_no &&
				( errors.street_no = t( 'errors.form.empty', {
					value: t( 'form_labels.countries.title' )
				} ) );
			! values.zip &&
				( errors.zip = t( 'errors.form.empty', {
					value: t( 'form_labels.zip' )
				} ) );
			! values.city &&
				( errors.city = t( 'errors.form.empty', {
					value: t( 'form_labels.place' )
				} ) );
		}

		return errors;
	};

	return (
		<Page title={t( 'settings.billing' )}>
			{ serverError && (
				<Alert type="error" show>
					<p>{ serverError }</p>
				</Alert>
			) }
			{ serverSuccess && (
				<Alert type="success" show>
					<p>{ serverSuccess }</p>
				</Alert>
			) }
			<Card>
				<Formik
					initialValues={ {
						first_name,
						last_name,
						email,
						country,
						street_no,
						zip,
						city,
						pp_accepted: ! repromptPp ? pp_accepted : '',
						tos_accepted: ! repromptTos ? tos_accepted : ''
					} }
					validate={ validate }
					validateOnChange={ false }
					validateOnBlur={ false }
					onSubmit={ onSubmit }
				>
					{ ( {
						values,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting,
						setFieldValue
					} ) => (
						<form onSubmit={handleSubmit}>
							<div className="row">
								<Input
									name="first_name"
									type="with-label"
									label={ t( 'form_labels.name' ) }
									onChange={ ( value, e ) => handleChange( e ) }
									value={ values.first_name }
									onErrorMessage={ errors.first_name as string }
								/>

								<Input
									name="last_name"
									type="with-label"
									label={ t( 'form_labels.surname' ) }
									onChange={ ( value, e ) => handleChange( e ) }
									value={ values.last_name }
									onErrorMessage={ errors.last_name as string }
								/>
							</div>

							<Input
								inputType="email"
								name="email"
								type="with-label"
								label="e-mail"
								onChange={ ( value, e ) => handleChange( e ) }
								value={ values.email }
								onErrorMessage={ errors.email as string }
							/>

							<Input
								inputType="select"
								name="country"
								type="with-label"
								label={ t( 'form_labels.countries.title' ) }
								selectValues={ [
									{
										optionLabel: 'AT',
										optionValue: t( 'form_labels.countries.at' )
									},
									{
										optionLabel: 'DE',
										optionValue: t( 'form_labels.countries.de' )
									},
									{
										optionLabel: 'IT',
										optionValue: t( 'form_labels.countries.it' )
									},
									{
										optionLabel: 'SI',
										optionValue: t( 'form_labels.countries.si' )
									}
								] }
								onChange={ ( value, event ) => handleChange( event ) }
								value={ values.country }
								onErrorMessage={ errors.country as string }
							/>

							{ ( values.country !== 'AT' || orderRequiresAddress ) && (
								<>
									<Input
										name="street_no"
										type="with-label"
										label={ t( 'form_labels.street' ) }
										onChange={ ( value, e ) => handleChange( e ) }
										value={ values.street_no }
										onErrorMessage={ errors.street_no as string }
									/>

									<div className="row">
										<Input
											name="zip"
											type="with-label"
											label={ t( 'form_labels.zip' ) }
											onChange={ ( value, e ) => handleChange( e ) }
											value={ values.zip }
											onErrorMessage={ errors.zip as string }
										/>
										<Input
											name="city"
											type="with-label"
											label={ t( 'form_labels.place' ) }
											onChange={ ( value, e ) => handleChange( e ) }
											value={ values.city }
											onErrorMessage={ errors.city as string }
										/>
									</div>
								</>
							) }

							{ anyReprompt && (
								<>
									{ repromptPp && (
										<CheckBox
											label={ t( 'basics.policy' ) }
											checked={ values.pp_accepted }
											onErrorMessage={ errors.pp_accepted as string }
											onChange={ ( v ) => setFieldValue( 'pp_accepted', privacy_policy_date )  }
											>
											<>
												{ t( 'form_labels.accept' ) }
												<a href="/settings/policy">{ t( 'basics.policy' ) }</a>
											</>
										</CheckBox>
									) }

									{ repromptTos && (
										<CheckBox
											label={ t( 'basics.gtc' ) }
										checked={ values.tos_accepted }
											onErrorMessage={ errors.tos_accepted as string }
											onChange={ ( v ) => setFieldValue( 'tos_accepted', terms_of_service_date ) }
											>
											<>
												{ t( 'form_labels.accept' ) }
												<a href="/settings/terms"> { t( 'basics.gtc' ) }</a>
											</>
										</CheckBox>
									) }

									<br />
								</>
							) }

							<Button
								onClick={ handleSubmit }
								label={ t( 'basket.to_payment' ) }
								disabled={ isSubmitting }
							/>
						</form>
					) }
				</Formik>
			</Card>
		</Page>
	);
};
