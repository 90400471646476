import { FC, Fragment } from 'react';
import { ReactComponent as Icon } from '../../../assets/vectors/edit-2.svg';

export const Stepper: FC<StepperProps> = ( {
	items,
	renderControl = true
}: StepperProps ) => {
	if ( !renderControl ) return <div />;
	return (
		<ul className="__Stepper">
			{items.map( ( { title, filled, active, onClick = () => true } ) => (
				<li
					aria-hidden
					onClick={onClick}
					className={`__Stepper-item ${
						!active ? '__Stepper-item-disabled' : ''
					}`}
				>
					{filled && <Icon />}
					<span>{title}</span>
				</li>
			) )}
		</ul>
	);
};
export type StepperItemsType = {
	/**
	 * The Title, DUH!
	 */
	title: string;
	/**
	 * If user has filled the step
	 */
	filled: boolean;
	/**
	 * Active step
	 */
	active: boolean;

	/**
	 * When clicked
	 */
	onClick?: () => void;
};
export interface StepperProps {
	/**
	 *  Manual control over the render
	 */
	renderControl?: boolean;
	/**
	 * List items
	 */
	items: StepperItemsType[];
}
Stepper.defaultProps = {};
