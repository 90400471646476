import { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { useRouter } from '../../routes/Router.hooks';
import { isLoggedIn } from '../../utils/helpers';

export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );

	if ( isLoggedIn() ) {
		route.push( '/' );
	}

	const [serverError, setServerError] = useState( '' );
	const [serverSuccess, setServerSuccess] = useState( '' );

	const onSubmit = ( values, { setSubmitting } ) => {
		HeyServer.post( '/pw-reset-request', values )
			.then( () => {
				setServerSuccess( t( 'settings.request_new_pw.success' ) );
				setTimeout( () => {
					route.push( '/login' );
				}, 5000 );
			} )
			.catch( ( e ) => setServerError( e ) )
			.finally( () => setSubmitting( false ) );
	};

	const validate = ( values ) => {
		const errors: any = {};
		if ( !values.email ) {
			errors.email = t( 'errors.form.empty', { value: 'E-Mail' } );
		}
		if ( !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test( values.email ) ) {
			errors.email = t( 'errors.form.email' );
		}
		return errors;
	};

	return (
		<Page title={t( 'settings.request_new_pw.title' )}>
			{serverError && (
				<Alert type="error" show>
					<p>{serverError}</p>
				</Alert>
			)}

			<Card>
				<div className="PasswordResetContainer">
					<Formik
						initialValues={{ email: '' }}
						validate={validate}
						validateOnChange={false}
						validateOnBlur={false}
						onSubmit={onSubmit}
					>
						{( { values, errors, handleChange, handleSubmit, isSubmitting } ) => (
							<form onSubmit={handleSubmit}>
								<span>{t( 'settings.request_new_pw.info' )}</span>
								<br />
								{serverSuccess && (
									<Alert type="success" show>
										<p>{ serverSuccess }</p>
									</Alert>
								)}
								{!serverSuccess && (
									<>
										<Input
											inputType="email"
											name="email"
											type="with-label"
											label="E-Mail"
											onChange={( value, e ) => handleChange( e )}
											value={values.email}
											onErrorMessage={errors.email as string}
										/>
										<Button
											actionType="submit"
											onClick={handleSubmit}
											label={t( 'settings.request_new_pw.title' )}
											disabled={isSubmitting}
										/>
									</>
								)}
							</form>
						)}
					</Formik>
				</div>
			</Card>
		</Page>
	);
};
