// eslint-disable jsx-props-no-spreading
/* eslint-disable prefer-destructuring */
/* eslint-disable consistent-return */
/* eslint-disable no-irregular-whitespace */
/* eslint-env es6 */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */

import 'moment/locale/de';
import Fuse from 'fuse.js';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-datetime/css/react-datetime.css';
import NumberFormat from 'react-number-format';
import { isLoggedIn, requestCameraAccess } from '../../utils/helpers';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { Loader } from '../../components/Loader';
import { Stepper, StepperItemsType } from '../../components/modular/Stepper';
import { ReactComponent as TrashSVG } from '../../assets/vectors/trash-2.svg';
import { ReactComponent as CameraSVG } from '../../assets/vectors/camera.svg';
import { Card } from '../../components/Card';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { HeyServer } from '../../utils/server';
import { CartSummary, TicketDataType } from '../../components/modular/CartSummary';
import { Input } from '../../components/Input';
import {
	getContact,
	getCustomerTypes,
	getPrice,
	getTicketTypes,
	CustomerTypeCustom,
	registerContacts,
	registerIdCards,
	ShopPresistor,
	uploadIdCards
} from './Shop.data';

export default ( props ): JSX.Element => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );

	// local state hooks
	const [ serverError, setServerError ] = useState( '' );
	const [ submitting, setSubmitting ] = useState( false );
	const [ localLoaded, setLocalLoaded ] = useState( false );

	const [ summary, setSummary ] = useState<TicketDataType>( {
		name: '',
		count: 0,
		price: 0
	} );

	const [ paxList, setPaxList ] = useState<CustomerTypeCustom[] | []>(
		[]
	);

	// redirect back to first step of params are corrupt
	const shopState: any = ShopPresistor().getSelections();
	const stateActiveOrder: any = ShopPresistor().getActiveOrder();

	if ( ! shopState.ttid || ! shopState.pax_type_count || ! shopState.summary )
		route.push( '/shop' );

	// HOOKS
	const { data, isLoading } = getCustomerTypes( false );
	const ticketTypes = getTicketTypes( false );
	const [ focused, setFocused ] = useState( { target: 0, active: false } );

	// load and cache contacts
	const { data: getContacts, isLoadingMore, size, setSize } = getContact( false );

	if ( ! isLoadingMore ) {
		if ( getContacts[0].next )
			setSize( size + 1 );

		ShopPresistor().setContacts( getContacts[0].results );
	}

	useEffect( () => {
		paxList.length > 0 &&
			ShopPresistor().setSelections( {
				summary,
				pax_infos: paxList
			} );
	}, [ paxList, summary ] );

	const stepperItems: StepperItemsType[] = [
		{
			title: t( 'progress.date' ),
			onClick: () => route.push( '/shop/select-date' ),
			filled: true,
			active: true
		},
		{
			title: t( 'progress.amount' ),
			onClick: () => route.push( '/shop/select-pax' ),
			filled: true,
			active: true
		},
		{
			title: t( 'progress.contacts' ),
			filled: false,
			active: true
		}
	];

	// server data is loaded
	if ( isLoading || ticketTypes.isLoading )
		return <Loader />;

	const customerType = data.results.find(
		( { id } ) => id === Number( shopState.ttid )
	);

	const ticketType = ticketTypes.data.results.find(
		( { id } ) => id === Number( shopState.ttid )
	);

	if (
		ticketType.is_flexible ||
		!ticketType.allow_presale ||
		ticketType.activation_type === 2
	) {
		stepperItems[0].filled = false;
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		stepperItems[0].onClick = () => {};
	}

	let for_time: string | null;

	if ( shopState.date ) {
		for_time = moment( shopState.date )
			.add( ticketType.start_hour, 'h' )
			.format( 'YYYY-MM-DDTHH:mm:ss' );
	} else if ( ticketType.allow_presale || ticketType.is_flexible ) {
		for_time = null;
	} else {
		for_time = moment().format( 'YYYY-MM-DDTHH:mm:ss' );
	}


	// METHODS
	// server calls
	const createOrder = ( load: CustomerTypeCustom[] ) => {
		const processOrder = (
			list: CustomerTypeCustom[],
			orderId: number
		) => {
			const payload: {
				ticket_type: number;
				for_time?: string;
				contacts: {
					customer_type_id: number;
					contact_id: number;
					rebate_card_id: number | null;
				}[];
			} = {
				ticket_type: Number( shopState.ttid ),
				contacts: []
			};

			if ( for_time )
				payload.for_time = for_time;

			payload.contacts = list.map(
				( { customer_type_info, contact_id, rebate_card_id }: any ) => {
					const r = {
						customer_type_id: customer_type_info.id || customer_type_info.customer_type_id,
						contact_id,
						rebate_card_id: null

					};

					if ( rebate_card_id )
						r.rebate_card_id = rebate_card_id;

					return r;
				}
			);

			HeyServer.post( `order/${orderId}/ticket/`, JSON.stringify( payload ) )
				.then( ( order: any ) => {
					ShopPresistor().setActiveOrder( order );
					route.push( `/basket` );
				} )

				.catch( ( error ) => {
					const code = error.response.data.code;
					const body = error.response.data;
					switch ( code ) {
						case 'json':
							setServerError( t( 'errors.default_msg' ) );
							break;
						case 'age-validation':
							if ( body.detail ) {
								const newList = [ ...list ];
								newList.forEach( ( listItem, index ) => {
									body.detail.forEach( ( el ) => {
										if ( Number( listItem.contact_id ) === Number( el ) ) {
											newList[index].formErrors.birthday =
												'Dieses Ticket ist für das angegebene Geburtsdatum ungültig';
										}
									} );
								} );

								setPaxList( newList );
							}
							break;
					}

					setSubmitting( false );
				} );
		};

		// if incert voucher mode, skip loading tickets as normal
		if ( ShopPresistor().getFlags().incert_package.status ) {
			route.push( `/basket` );
			return;
		}

		// If there is already an order, just add tickets to it
		if ( stateActiveOrder && stateActiveOrder.pk )
			return processOrder( load, stateActiveOrder.pk );

		// else create a new one
		return HeyServer.post( 'order/' )
			.then( ( order: any ) => processOrder( load, order.pk ) )
			.catch( ( e ) => {
				setServerError( e );
				setSubmitting( false );
			} );
	};

	// if the form is valid or not
	const isFormValid = (): boolean => {
		let isValid = true;
		const list = [ ...paxList ];

		list.forEach( ( { formEntries, customer_type_info, formErrors } ) => {
			const { age_from, age_to, rebate_card } = customer_type_info;
			const requiresBirthday = Boolean( age_from || age_to );
			const requiresImage = Boolean( rebate_card );
			formErrors.name = '';
			formErrors.birthday = '';
			formErrors.image = '';

			if ( !formEntries.name ) {
				formErrors.name = t( 'errors.form.empty', {
					value: customer_type_info.label
				} );
				isValid = false;
			}

			if ( requiresBirthday ) {
				const valid_date = moment(
					formEntries.birthday,
					'DD.MM.YYYY',
					true
				).isValid();

				if ( ! formEntries.birthday || ! valid_date ) {
					formErrors.birthday = t( 'errors.form.birthday' );
					isValid = false;
				}
			}

			if ( requiresImage && ! formEntries.image ) {
				formErrors.image = t( 'errors.form.id_card' );
				isValid = false;
			}
		} );

		setPaxList( list );
		return isValid;
	};

	// MAIN function after submission, order of API calls
	const handleFormSubmission = () => {
		setSubmitting( true );

		if ( ! isFormValid() )
			return setSubmitting( false );

		// if there is an image upload in the contacts
		const listContainsImage = paxList.some(
			( { formEntries } ) => 'image' in formEntries
		);

		// register contacts
		return registerContacts( paxList )
			.then( ( res: any ) => {
				// update the extra info for incert package code call
				if ( ShopPresistor().getFlags().incert_package.status ) {
					const contactsForIncertCall = res.map(
						( { contact_id, customer_type_info } ) => ( {
							contact_id,
							customer_type_id: customer_type_info.customer_type_id
						} )
					);

					ShopPresistor().flagOn( 'incert_package', {
						contacts: contactsForIncertCall,
						code: ShopPresistor().getFlagData( 'incert_package' ).code,
						for_time: moment().format( 'YYYY-MM-DDTHH' )
					} );
				}

				if ( listContainsImage ) {
					registerIdCards( res )
						.then( ( response: any ) =>
							uploadIdCards( response )
								.then( () => createOrder( response ) )
								.catch( ( e ) => {
									setServerError( e );
									setSubmitting( false );
								} )
						)
						.catch( ( e ) => {
							setServerError( e );
							setSubmitting( false );
						} );
				} else {
					return createOrder( res );
				}
			} )

			.catch( ( e ) => {
				setServerError( e );
				setSubmitting( false );
			} );
	};

	// summary box has its data
	if ( ! summary.name ) {
		const { price, name, count }: any = shopState.summary;
		setSummary( {
			price,
			name,
			count
		} );

		return <Loader />;
	}

	// pax render list is generated
	if ( ! paxList || paxList.length < 1 ) {
		if ( ! localLoaded && ( shopState.pax_infos as any ).length > 0 ) {
			setPaxList( shopState.pax_infos as any );
			setLocalLoaded( true );
		}

		return <Loader />;
	}

	// if awaiting a server call
	if ( submitting )
		return <Loader />;

	const renderDropdown = ( name: string ) => {
		const fuse = new Fuse( ShopPresistor().getContacts(), {
			keys: ['name'],
			minMatchCharLength: 2
		} );

		const results = fuse.search( name );
		return results.slice( 1, 4 );
	};

	const updateName = ( index, value ) => {
		const copyOfPaxInfo = [ ...paxList ];
		copyOfPaxInfo[index].formEntries.name = value;
		setPaxList( copyOfPaxInfo );
	};

	const removePax = ( customer_type_info, index ) => {
		const copyOfPaxInfo = [ ...paxList ];
		const copyOfCounterList = [ ...shopState.pax_type_count ];
		copyOfCounterList.forEach(
			( { customer_type_id, counterValue, show_in_list }, i ) => {
				if (
					customer_type_info.id === customer_type_id &&
					counterValue > 0 &&
					show_in_list
				) {
					copyOfCounterList[ i ].counterValue -= 1;
				}
			}
		);

		copyOfPaxInfo.splice( index, 1 );
		setPaxList( copyOfPaxInfo );

		// update local state
		ShopPresistor().setSelections( {
			pax_infos: copyOfPaxInfo,
			pax_type_count: copyOfCounterList
		} );

		// if no pax counts, then simply set the state and reset summary
		if ( copyOfPaxInfo.length === 0 ) {
			ShopPresistor().setSelections( {
				pax_infos: copyOfPaxInfo,
				pax_type_count: copyOfCounterList,
				summary: {
					count: 0,
					name: '',
					price: 0
				}
			} );

			route.goBack();
		}

		// otherwise get new price and update the summarygp
		return getPrice( copyOfCounterList, customerType.id ).then( ( price: any ) => {
			const newSummary = {
				price,
				name: customerType.name,
				count: copyOfCounterList
					.map( ( { counterValue } ) => counterValue )
					.reduce( ( a, b ) => a + b )
			};

			setSummary( newSummary );
			ShopPresistor().setSelections( {
				pax_infos: copyOfPaxInfo,
				pax_type_count: copyOfCounterList,
				summary: newSummary
			} );
		} );
	};

	return (
		<Page title={ t( 'shop.contacts.title' ) }>
			<Stepper
				items={ stepperItems }
				renderControl={ ! ShopPresistor().getFlags().incert_package.status }
			/>
			{ serverError && (
				<>
					<br />
					<Alert type="error" show>
						<p>{ serverError }</p>
					</Alert>
				</>
			) }
			<div className="PaxInfoContainer">
				{ paxList.map(
					(
						{ customer_type_info, formEntries, formErrors }: any,
						index: number
					) => {
						const { rebate_card, name, age_from, age_to, label, placeholder } =
							customer_type_info;

						return (
							<Card>
								<h2>{ name }</h2>
								<div style={ { width: '100%', position: 'relative' } }>
									<Input
										required
										type="with-label"
										label={ label }
										onChange={ ( value ) => updateName( index, value ) }
										value={ formEntries.name }
										placeholder={ placeholder }
										autoComplete="off"
										name="formEntries.name"
										onErrorMessage={ formErrors.name }
										onFocus={ () => setFocused( { target: index, active: true } ) }
										onBlur={ () =>
											setTimeout(
												() => setFocused( { target: 0, active: false } ),
												100
											)
										}
									/>
									{ isLoggedIn() &&
										focused.active &&
										focused.target === index &&
										paxList[ index ].formEntries.name.length > 1 && (
											<div className="autocomplete">
												{ renderDropdown( paxList[ index ].formEntries.name ).map(
													( { item }: any ) => (
														<div
															aria-hidden="true"
															onKeyDown={ () => updateName( index, item.name ) }
															onClick={ () => updateName( index, item.name ) }
														>
															<span>{ item.name }</span>
														</div>
													)
												) }
											</div>
										) }
								</div>

								{ Boolean( age_from || age_to ) && (
									<>
										<div className="__Input-container">
											<label htmlFor="formEntries.name">Geburtsdatum</label>
											<NumberFormat
												value={ formEntries.birthday }
												format="##.##.####"
												inputMode="numeric"
												name="Geburtsdatum"
												className="__Input __Input-with-label"
												placeholder="TT.MM.JJJJ"
												onValueChange={ ( values ) => {
													const { formattedValue } = values;
													const copyOfPaxInfo: any = [ ...paxList ];
													copyOfPaxInfo[ index ].formEntries.birthday =
														formattedValue;
													setPaxList( copyOfPaxInfo );
												} }
											/>
											{ formErrors.birthday && (
												<Alert type="error" show>
													{ formErrors.birthday }
												</Alert>
											) }
										</div>
									</>
								) }

								{ Boolean( rebate_card ) && (
									<div className="__fotoUploader">
										<span>{ t( 'shop.contacts.picture' ) }</span>
										<Button
											label={ t( 'shop.contacts.upload' ) }
											onClick={ async () => {
												requestCameraAccess().then( ( result ) => {
													document
														?.getElementById( `fileInput__${index}` )
														?.click();
												} );
											} }
											Icon={ <CameraSVG /> }
										/>

										<input
											type="file"
											value=""
											capture
											accept="image/*,.pdf"
											id={ `fileInput__${index}` }
											data-cy="file_upload"
											style={ { display: 'none' } }
											onChange={ ( e ) => {
												const copyOfPaxInfo = [ ...paxList ];
												const reader = new FileReader();
												const files: any = e.target.files;
												const file = files[0];

												// Convert the file to base64 text
												reader.readAsDataURL( file );

												// on reader load somthing...
												reader.onload = () => {
													copyOfPaxInfo[ index ].formEntries.image =
														reader.result as any;
													setPaxList( copyOfPaxInfo );
												};
											} }
										/>

										<br />

										{ formEntries.image && (
											<img src={ formEntries.image } alt="ausweisfoto" />
										) }
										{ formErrors.image && (
											<Alert type="error" show>
												{ formErrors.image }
											</Alert>
										) }
									</div>
								) }

								{ ! ShopPresistor().getFlags().incert_package.status && (
									<div
										className="__deleteIcon"
										aria-hidden
										onClick={ () => removePax( customer_type_info, index ) }
									>
										<TrashSVG />
									</div>
								) }
							</Card>
						);
					}
				) }
			</div>

			<CartSummary
				disabled={ summary.count === 0 }
				btnLabel={ t( 'buttons.continue' ) }
				ticketData={ summary }
				onClick={ handleFormSubmission }
			/>
		</Page>
	);
};
