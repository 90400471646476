import ReactDOM from 'react-dom';
// components
import reportWebVitals from './utils/reportWebVitals';
// assets
import './i18n';
import './assets/App.scss';
import './assets/tailwind.css';
import '@fontsource/source-sans-pro';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700.css';
import App from './App';

ReactDOM.render( <App />, document.getElementById( 'root' ) );
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
