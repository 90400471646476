import { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { mutate } from 'swr';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Pagination } from '../../components/modular/Pagination';
import { getOrders } from './Settings.data';
import { useRouter } from '../../routes/Router.hooks';
import { Order } from '../../types/Models';
import { Divider } from '../../components/Divider';
import { Button } from '../../components/Button';
import { ReactComponent as TrashSVG } from '../../assets/vectors/trash-2.svg';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';

type TicketToContact = {
	ticket_name: string;
	contact_name: string;
};

export default (): any => {
	const [page, setPage] = useState( 1 );
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	const [submitting, setSubmitting] = useState( false );
	const [serverError, setServerError] = useState( '' );
	const [serverSuccess, setServerSuccess] = useState( '' );
	const { data, isLoading } = getOrders( true, page );
	useEffect( () => {
		// if server message returned clsoe the modal and scroll to the top of the page
		if ( serverError || serverSuccess ) {
			document.getElementById( 'close-modal' )?.click();
			window.scrollTo( 0, 0 );
		}
	} );

	if ( isLoading )
		return <Loader />;

	const activeOrders = data.results.filter(
		( { payment_status } ) => payment_status === 'succeeded'
	);

	if ( ! activeOrders.length ) {
		// Identical to Shop/Cart.page.tsx, wasn't deduplication the whole point of components?
		return (
			<Page title={t( 'settings.orders.title' )}>
				<div className="EmptyCartContainer">
					<Card>
						<p>{t( 'settings.orders.empty_info' )}</p>
						<h1>{process.env.REACT_APP_C2T_TEXT}</h1>
						<Button
							onClick={() => route.push( '/shop' )}
							label={t( 'buttons.select_ticket' )}
						/>
					</Card>
				</div>
			</Page>
		);
	}

	const generateTicketToContact = ( tickets: Order['tickets'] ): Array<TicketToContact> => {
		const items = [];

		tickets?.forEach( ( { typ, contacts } ) => {
			const ticket_name: string = typ?.ticket_type?.name as string;
			contacts?.forEach( ( { contact } ) => {
				const contact_name: string = contact?.name as string;
				const entry: TicketToContact = {
					ticket_name,
					contact_name
				};
				items.push( entry as never );
			} );
		} );

		return items;
	};

	const onDeleteOrder = ( orderId: number ) =>
		HeyServer.delete( `/order/${orderId}/payment/` )
			.then( () => {
				mutate( [`/order/?ordering=-pk&page=${page}`, true] );
				setServerSuccess( t( 'settings.orders.prompt.success' ) );
			} )
			.catch( ( e ) => setServerError( e ) )
			.finally( () => setSubmitting( false ) );

	const submit = ( orderId: number ) =>
		confirmAlert( {
			customUI: ( { onClose }: any ) => (
				<div className="Prompt">
					<h1>{t( 'settings.orders.prompt.title' )}</h1>
					<p>
						{t( 'settings.orders.prompt.info_1' )}
						<br />
						<br />
						{t( 'settings.orders.prompt.info_2' )}
					</p>
					<div>
						<Button
							disabled={submitting}
							id="close-modal"
							label={t( 'basics.abort' )}
							onClick={onClose}
						/>
						<Button
							disabled={submitting}
							label={t( 'basics.cancel' )}
							type="secondary"
							onClick={() => {
								setSubmitting( true );
								onDeleteOrder( orderId );
							}}
						/>
					</div>
				</div>
			)
		} );

	const showCancellationButton = ( is_cancelled, payment_at, tickets ) => {
		const areTicketsValid = () => {
			let valid = true;
			tickets.forEach( ( { valid_from } ) => {
				if ( moment( valid_from ).isBefore( moment() ) ) {
					valid = false;
				}
			} );
			return valid;
		};

		return (
			!is_cancelled &&
			areTicketsValid() &&
			moment( payment_at ).add( 2, 'weeks' ).isSameOrAfter( moment() )
		);
	};

	return (
		<Page title={t( 'settings.orders.title' )}>
			{ serverError && (
				<Alert type="error" show>
					<p>{ serverError }</p>
				</Alert>
			) }
			{ serverSuccess && (
				<Alert type="success" show>
					<p>{ serverSuccess }</p>
				</Alert>
			) }
			<Card>
				<div className="OrdersTicketItemContainer">
					{ activeOrders.map(
						( { total_price, payment_at, tickets, is_cancelled, pk } ) => (
							<Fragment key={ pk }>
								<div className="OrdersTicketItem">
									<div className="OrdersTicketItemHeader">
										<span>{ moment( payment_at ).format( 'DD.MM.YYYY' ) }</span>
										<div style={ { display: 'flex', flexDirection: 'row' } }>
											{ is_cancelled && (
												<span
													style={ {
														color: 'red',
														textTransform: 'capitalize',
														fontWeight: 400,
														fontSize: 14,
														marginRight: 5
													} }
												>
													{ t( 'basics.cancelled' ) }
												</span>
											) }

											<span className={ `${is_cancelled ? 'lineThrough' : ''}` }>
												{ ( Math.round( total_price * 100 ) / 100 )
													.toFixed( 2 )
													.replace( '.', ',' ) }{ ' ' }
												&euro;
											</span>
										</div>
									</div>
									{ generateTicketToContact( tickets ).map(
										( { ticket_name, contact_name } ) => (
											<div className="OrdersTicketItemTickets">
												<div>{ ticket_name }</div>
												<div>{ contact_name }</div>
											</div>
										)
									) }

									<br />

									{ showCancellationButton(
										is_cancelled,
										payment_at,
										tickets
									) && (
										<Button
											Icon={ <TrashSVG /> }
											label={ t( 'basics.cancel' ) }
											type="secondary"
											onClick={ () => submit( pk ) }
										/>
									) }
								</div>

								<Divider />
							</Fragment>
						)
					) }
				</div>
			</Card>
			<Card>
				<Pagination
					maxPages={ null }
					currentPage={ page }
					nextPage={ () => data.next && setPage( page + 1 ) }
					previousPage={ () => data.previous && setPage( page - 1 ) }
				/>
			</Card>
		</Page>
	);
};
