import useSWR, { useSWRInfinite } from 'swr';
import { SWRReturnObject } from '../../types/Types';
import { getLocalResource } from '../../utils/server';

export const getSettings = ( forceAPICall: boolean ): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [`/settings/`, forceAPICall] );
	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};
export const getFaq = ( forceAPICall: boolean ): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [`/faq/`, forceAPICall] );
	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};

export const getUser = ( forceAPICall: boolean ): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [`/user/`, forceAPICall] );
	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};

export const getNotifications = ( forceAPICall: boolean ): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [`/notification/`, forceAPICall] );
	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};
export type PushNotesType = {
	count: number;
	next: string;
	previous: string;
	results: any[];
	timestamp: string;
};

export const initPushNotesType: PushNotesType = {
	count: 0,
	next: '',
	previous: '',
	results: [],
	timestamp: ''
};

export const getPushNotes = () => {
	const get = () => {
		const r = getLocalResource( '/notification/' ) as unknown as PushNotesType;
		if ( !r ) {
			init();
		}
		return r;
	};
	const init = (): void => {
		if ( window.localStorage.getItem( '/notification/' ) === null ) {
			window.localStorage.setItem(
				'/notification/',
				JSON.stringify( initPushNotesType )
			);
		}
	};
	return {
		get
	};
};

export const getOrders = (
	forceAPICall: boolean,
	pageIndex = 1
): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [
		`/order/?ordering=-pk&page=${pageIndex}`,
		forceAPICall
	] );
	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};
