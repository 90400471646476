/* eslint-disable consistent-return */
import { getLocalResource } from './server';
import { getUser } from '../features/Settings/Settings.data';
import { SWRReturnObject } from '../types/Types';

// second copy in native App.tsx
export const convertToKebabCase = ( string: string ) =>
	string.replace( /\s+/g, '-' ).toLowerCase();

export const isApp = (): boolean => Boolean( window.isNativeApp );

export const capitalize = ( s: string ): string => {
	if ( typeof s !== 'string' ) return '';
	return s.charAt( 0 ).toUpperCase() + s.slice( 1 );
};

// if there is an auth token in the window, make sure to set it onto the localstorage
export const setTokenFromWindow = (): any => {
	if ( !isApp() ) {
		return;
	}
	const main = window as any;
	if ( main.ReactNativeUserToken ) {
		window.localStorage.setItem( 'user_token', main.ReactNativeUserToken );
	}
};

export const areWeTestingWithJest = () => {
	if ( !process ) {
		return false;
	}
	return process.env.JEST_WORKER_ID !== undefined;
};

export const isLoggedIn = (
	returnData = false
): boolean | [boolean, SWRReturnObject] => {
	let loggedIn = false;
	const user_token = getLocalResource( 'user_token' );

	if ( user_token && user_token.token && !user_token.anon ) {
		loggedIn = true;
	}

	if ( !returnData ) {
		return loggedIn;
	}

	// call the user endpoint only when the token is set
	if ( loggedIn ) {
		const userData = getUser( true );
		return [loggedIn, userData];
	}

	return false;
};

export const isLoggedInAnon = (): boolean => {
	let loggedIn = false;
	const user_token = getLocalResource( 'user_token' );

	if ( user_token && user_token.token && user_token.anon ) {
		loggedIn = true;
	}

	return loggedIn;
};

export const formatPrice = ( amount: any ): string =>
	`${String( Number( amount ).toFixed( 2 ).toString().replace( '.', ',' ) )} €`;

export const downloadBlob = ( blob, filename ) => {
	const url = URL.createObjectURL( blob );
	const a = document.createElement( 'a' );

	a.href = url;
	a.download = `${filename}_TT2N_Ticket.png`;
	a.click();

	return a;
};

export const asyncReadBlob = async ( blob ) =>
	new Promise( ( resolve ) => {
		const reader = new FileReader();
		reader.readAsDataURL( blob );
		reader.onloadend = function () {
			const base64data = reader.result;
			resolve( base64data );
		};
	} );

export const postMessage = ( method: any, data?: any ) => {
	if ( !isApp() ) {
		return true;
	}

	let payload: any = {
		prefix: `TT2N`,
		method,
		data: data || null
	};

	payload = JSON.stringify( payload );

	if ( 'FlutterWebView' in window ) {
		window.FlutterWebView.postMessage( payload );
	} else {
		console.log( `Webview not found, postMessage: ${payload}` );
	}
};

export const requestCameraAccess = async () =>
	new Promise( ( resolve ) => {
		postMessage( 'REQUEST_CAMERA_ACCESS' );

		window.FlutterWebView.addEventListener(
			'message',
			( event: MessageEvent ) => {
				window.FlutterCameraAccess = Boolean( event.data );
				resolve( event.data );
			},
			{ once: true }
		);
	} );

export const parseMessage = ( event? ) => {
	const payload = event.data;
	if ( payload.prefix !== `TT2N` ) {
		throw new Error( 'Prefix does not match!' );
	}

	return payload;
};

export const shareImage = ( blob: Blob, filename: string ) => {
	if ( isApp() ) {
		return asyncReadBlob( blob ).then( ( image ) =>
			postMessage( 'SHARE_TICKET', {
				image,
				filename
			} )
		);
	}

	return downloadBlob( blob, filename );
};

export const arrayBufferToBase64 = ( buffer ) => {
	const binary_string = window.atob( buffer );
	const len = binary_string.length;
	const bytes = new Uint8Array( len );

	for ( let i = 0; i < len; i += 1 ) {
		bytes[i] = binary_string.charCodeAt( i );
	}

	return bytes.buffer;
};

export const downloadOffline = ( name, image ) => {
	const a = document.createElement( 'a' );

	a.href = image;
	a.download = `${name}.png`;

	return a.click();
};

// formats the dd.mm.yyyy to yyyy-mm-dd
export const formatDeutschDate = ( str ) =>
	str.replaceAll( '.', '-' ).split( '-' ).reverse().join( '-' );

export const isOnline = (): boolean => Boolean( window.navigator.onLine );

export const fileToBase64 = ( e: any, multiple = false ) => {
	// get the files
	const { files } = e.target;
	// Process each file
	const allFiles: any = [];

	for ( let i = 0; i < files.length; i += 1 ) {
		const file = files[i];
		// Make new FileReader
		const reader = new FileReader();
		// Convert the file to base64 text
		reader.readAsDataURL( file );
		// on reader load somthing...
		reader.onload = () => {
			// Make a fileInfo Object
			const fileInfo: any = {
				name: file.name,
				type: file.type,
				size: `${Math.round( file.size / 1000 )} kB`,
				base64: reader.result,
				file
			};

			// Push it to the state
			allFiles.push( fileInfo );

			// If all files have been proceed
			if ( allFiles.length === files.length ) {
				// Apply Callback function
				if ( multiple ) {
					return allFiles;
				}

				return allFiles[0];
			}
		};
	}

	return true;
};
