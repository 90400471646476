import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from '../../components/Loader';
import { useRouter } from '../../routes/Router.hooks';
import { isOnline, isApp, postMessage } from '../../utils/helpers';
import { ShopPresistor } from '../Shop/Shop.data';

export default (): any => {
	useEffect( () => {
		setTimeout( () => {
			ShopPresistor().reset();
			if ( isOnline() ) localStorage.clear();
			postMessage( 'REMOVE_SESSION' );
			if ( isApp() ) {
				window.location.href = '/index.html';
			} else {
				window.location.href = '/';
			}
		}, 200 );
	} );

	return <Loader />;
};
