import { FC, ReactNode } from 'react';
import { ReactComponent as Icon } from '../../../assets/vectors/arrow-left.svg';

export const Header: FC<HeaderProps> = ( {
	title,
	onBack,
	renderControl = true,
	rightItems
}: HeaderProps ) => (
	<div className="__Header">
		{renderControl && onBack && (
			<button type="button" onClick={onBack}>
				<Icon />
			</button>
		)}
		<p>{title}</p>
		<div className="__Header-rightItems">{rightItems}</div>
	</div>
);

export interface HeaderProps {
	/**
	 * Title text
	 */
	title: string;
	/**
	 * JSX children for right div contents
	 */
	rightItems?: ReactNode;
	/**
	 *  Manual control over the render
	 */
	renderControl?: boolean;
	/**
	 * When back button is clicked
	 */
	onBack?: () => void;
}
Header.defaultProps = {};
