import moment from 'moment';
import { Page } from '../../components/Page';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { getNotifications } from './Settings.data';
import { useRouter } from '../../routes/Router.hooks';
import { Divider } from '../../components/Divider';

export default (): any => {
	const route = useRouter();
	const { data, isLoading } = getNotifications( false );
	if ( isLoading ) {
		return <Loader />;
	}
	const notificationId = route.pathname.replace( '/settings/notifications/', '' );
	const item = data.results.find( ( { id } ) => id === Number( notificationId ) );
	if ( !item ) {
		route.push( '/' );
		return <Loader />;
	}
	const { title, publish_at, text, link } = item;
	return (
		<Page title="Benachrichtigungen">
			<Card>
				<div className="NotificationListItem">
					<div>
						<span className="title">{title}</span>
						<br />
						<span className="date">
							{moment
								.parseZone( publish_at )
								.local( true )
								.format( 'DD.MM.YYYY, HH:MM' )}{' '}
							Uhr
						</span>
					</div>
					<br />
					<Divider />
					<p>{text}</p>
					{link && (
						<a href={link} target="_blank" rel="noreferrer">
							{link}
						</a>
					)}
				</div>
			</Card>
		</Page>
	);
};
