/* eslint-disable no-return-assign */
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Alert } from '../components/Alert';
import logo from '../assets/logo.png';

// constants
const REDIRECT_AFTER = 5000;
const REDIRECT_LOCATION = '/';

// initiate Sentry in the app
Sentry.init( {
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [ new BrowserTracing() ],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
} );

export default ( { children }: { children: JSX.Element } ): JSX.Element => {
	const ErrorFallback = () => (
		<div>
				<img src={ logo } alt="logo" className="__Custom-logo-bottom" />

				<Alert show floatCenter type="error" showIcon={ false }>
					<h1 style={ { color: '#4D5152', fontSize: 25 } }>Hoppala!</h1>

					<p
						style={ {
							color: '#4D5152',
							textAlign: 'center',
							margin: 0,
							fontSize: 18,
							marginTop: 20
						} }
					>
						Die Technik spielt leider gerade nicht mit. Bitte versuche es später
						nochmal.
					</p>

					<p
						style={ {
							color: '#4D5152',
							textAlign: 'center',
							margin: 0,
							marginTop: 20
						} }
					>
						Unsere Leute wurden benachrichtigt um den Fehler zu beheben.
					</p>

					<p
						style={ {
							color: '#4D5152',
							textAlign: 'center',
							margin: 0,
							marginTop: 20
						} }
					>
						Sie werden in Kürze weitergeleitet...
					</p>
				</Alert>
		</div>
	);

	const handleError = ( error, target ) => {
		if ( process.env.REACT_APP_MODE === 'dev' )
			console.error( 'ERROR: ', error );
		else
			Sentry.captureException( error );

		setTimeout( () => {
			// clear up the app state in case of a crash
			localStorage.clear();
			window.location.href = REDIRECT_LOCATION;
		}, REDIRECT_AFTER );
	};

	return (
		<Sentry.ErrorBoundary onError={ handleError } fallback={ ErrorFallback }>
			{ children }
		</Sentry.ErrorBoundary>
	);
};
