import { FC, ReactNode } from 'react';
import { ReactComponent as ArrowIcon } from '../../../assets/vectors/arrow-right.svg';

export const VerticalMenu: FC<VerticalMenuProps> = ( {
	items,
	type,
	onClick = () => true
}: VerticalMenuProps ) => (
	<div className="VerticalMenu">
		{items.map( ( data, index ) => (
			<div
					className={ `VerticalMenu__item${( index + 1 === items.length ) ? ' last' : ( data as MenuItems ).disabled ? ' disabled' : ''}` }
				onClick={() =>
					type === 'settings-menu' &&
					!( data as MenuItems ).disabled &&
					onClick( ( data as MenuItems ).address )
				}
				aria-hidden="true"
			>
				{ type === 'settings-menu' && (
					<>
						<div>
							<span>{ ( data as MenuItems ).Icon }</span>

							{
								( data as MenuItems ).badge !== 0 &&
								( data as MenuItems ).badge &&
								<span className="VerticalMenu__item--badge">{( data as MenuItems ).badge}</span>
							}

							<p>{ ( data as MenuItems ).label }</p>
						</div>

						<ArrowIcon />
					</>
				) }

				{ type === 'license-list' && (
					<>
						<div className="VerticalMenu__threesection">
							<p>
								<a
									rel="noreferrer"
									target="_blank"
									aria-label="open repo"
									href={ ( data as LicenseItems ).repository }
								>
									{ ( data as LicenseItems ).name }
								</a>
							</p>

							<p>{ ( data as LicenseItems ).licenses }</p>

							<p>{ ( data as LicenseItems ).version }</p>
						</div>
					</>
				) }
			</div>
		) )}
	</div>
);

export type MenuItems = {
	/**
	 * Button text label
	 */
	label: string;

	/**
	 * Badge to show unread messages
	*/
	badge?: number;

	/**
	 * Button text label
	 */
	address: string;

	/**
	 * Vector file as React Element. Ignore its value in Storybook
	 */
	Icon: ReactNode | JSX.Element;
	disabled: boolean;
};

export type LicenseItems = {
	/**
	 * name of the repo
	 */
	name: string;

	/**
	 * name of the license
	 */
	licenses: string;

	/**
	 * repo URL
	 */
	repository: string;

	/**
	 * version number
	 */
	version: string;
};

export interface VerticalMenuProps {
	/**
	 * Type of render, supports either license list or settings menu
	 */
	type: 'settings-menu' | 'license-list';
	/**
	 * VerticalMenu active value
	 */
	items: MenuItems[] | LicenseItems[];
	/**
	 * Menu click
	 */
	onClick?: ( label: string ) => void;
}
VerticalMenu.defaultProps = {};
