import { FC, ReactNode } from 'react';
import { ReactComponent as CheckIcon } from '../../assets/vectors/check.svg';

export const CheckBox: FC<CheckBoxProps> = ( {
	checked,
	label,
	disabled,
	onChange,
	children,
	onErrorMessage
}: CheckBoxProps ) => {
	const classNames: string[] = ['__CheckBox'];
	checked && classNames.push( `__CheckBox-checked` );
	disabled && classNames.push( `__CheckBox-disabled` );
	onErrorMessage && classNames.push( `__CheckBox-error` );
	const className: string = classNames.join( ' ' );
	const callOnChange = () => onChange( !checked );
	return (
		<div
			className={className as string}
			onClick={callOnChange}
			aria-hidden="true"
		>
			<input
				type="checkbox"
				value={+checked}
				name={label}
				id={label}
				onChange={callOnChange}
			/>
			<div className="checkbox" aria-hidden="true" onClick={callOnChange}>
				{checked && <CheckIcon />}
			</div>
			<span>{children}</span>
			{onErrorMessage && <p>{onErrorMessage}</p>}
		</div>
	);
};

export interface CheckBoxProps {
	/**
	 * Label text
	 */
	label: string;
	/**
	 * Content
	 */
	children: ReactNode;
	/**
	 * Status of checkbox
	 */
	checked: boolean;
	/**
	 * Is the input disabled
	 */
	disabled?: boolean;
	/**
	 * when check status changes
	 */
	onChange: any;
	/**
	 * Condition AND message for rendering a error message box below the input
	 */
	onErrorMessage?: string;
}
CheckBox.defaultProps = {};
