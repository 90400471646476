import { FC } from 'react';

export const Loader: FC<LoaderProps> = ( { message = '', show = true, mode = 'full' }: LoaderProps ) => {
	const classes = [ 'Loader', mode ];
	! show && classes.push( 'hide' );

	return (
		<div className={ classes.join( ' ' ) } data-testid="overlay-loader">
			<div className="Loader__inner">
				<div className="Loader__content">
					<span className="Loader__spinner" />

					{ message && <span>{ message }</span> }
				</div>
			</div>
		</div>
	);
};

export interface LoaderProps {
	/**
	 * Message text below the icon
	 */
	message?: string;

	/**
	 * Wether overlay should be visible or not.
	 */
	show?: boolean;

	/**
	 * Mode for the loader.
	 */
	mode?: 'full' | 'overlay';
}

Loader.defaultProps = {};
