import { FC, ReactNode } from 'react';
import { ReactComponent as InfoIcon } from '../../assets/vectors/info.svg';
import { ReactComponent as CheckIcon } from '../../assets/vectors/check.svg';
import { ReactComponent as XIcon } from '../../assets/vectors/x.svg';

export const Alert: FC<AlertProps> = ( {
	type,
	show,
	dismissed,
	children,
	showIcon = true,
	floatCenter = false
}: AlertProps ) => {
	const classNames: string[] = ['Alert'];
	type && classNames.push( type );
	show && classNames.push( `show` );
	! show && classNames.push( `hide` );
	floatCenter && classNames.push( `float` );

	const className: string = classNames.join( ' ' );

	return (
		<div className={ className as string }>
			<div>
				{ showIcon && ( type === 'success' ? <CheckIcon /> : <InfoIcon /> ) }

				{ children }
			</div>

			{ dismissed && (
				<button onClick={ dismissed } className="Alert__close" type="button">
					<XIcon />
				</button>
			) }
		</div>
	);
};

export interface AlertProps {
	/**
	 * The text in the body
	 */
	children: ReactNode;

	/**
	 * The text in the body
	 */
	show: boolean;

	/**
	 * Hide the icon on the left
	 */
	showIcon?: boolean;

	/**
	 * The style types of the alert
	 */
	type: 'success' | 'warning' | 'error';

	/**
	 * Callback when X button is pressed
	 */
	dismissed?: () => void;

	/**
	 * Position absolute in the center of the page
	 */
	floatCenter?: boolean;
}

Alert.defaultProps = {};
