/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import { mutate } from 'swr';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { TagList } from '../../components/modular/TagList';
import { ReactComponent as TrashSVG } from '../../assets/vectors/trash-2.svg';
import { getNotifications, getUser } from './Settings.data';
import { Divider } from '../../components/Divider';
import { useRouter } from '../../routes/Router.hooks';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { isOnline } from '../../utils/helpers';

export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );
	const { data, isLoading } = getNotifications( true );
	const userData = getUser( false );
	const [serverError, setServerError] = useState( '' );
	const [tags, setTags] = useState<any>( null );
	if ( isLoading || userData.isLoading ) {
		return <Loader />;
	}
	const updateTagCounts = (): void => {
		const read = data.results.filter( ( { is_read } ) => is_read ).length;
		const unread = data.results.filter( ( { is_read } ) => !is_read ).length;
		const newTags = [
			{
				label: `${t( 'basics.new' )} (${unread})`,
				active: true
			},
			{
				label: `${t( 'basics.read' )} (${read})`,
				active: false
			}
		];
		isOnline() &&
			newTags.push( {
				label: t( 'basics.settings' ),
				active: false
			} );
		return setTags( newTags );
	};

	if ( !tags ) {
		updateTagCounts();
	}

	const user = userData.data.results[0];

	// server calls
	const updateSettingsTicketUpdates = () =>
		HeyServer.put( `/user/${user.pk}/`, {
			push_reminders: !user.push_reminders
		} )
			.then( () => mutate( '/user/' ) )
			.catch( () => setServerError( 'settings.notifications.settings.error' ) );

	const updateSettingsAds = () =>
		HeyServer.put( `/user/${user.pk}/`, {
			push_adverts: !user.push_adverts
		} )
			.then( () => mutate( '/user/' ) )
			.catch( () => setServerError( 'settings.notifications.settings.error' ) );

	// custom switch component styles
	const switchStyle = {
		offColor: '#e2e5e6',
		checkedIcon: false,
		uncheckedIcon: false,
		handleDiameter: 20
	};

	// generate read/unread notifications list
	const generateList = (): any[] =>
		data.results.filter( ( { is_read } ) =>
			isUnreadTabActive ? !is_read : is_read
		);

	// notification item clicked
	const itemClicked = ( id: number ) => {
		mutate( ['/notification/', true] );
		HeyServer.put( `/notification/${id}/`, { is_read: true } ).finally( () =>
			route.push( `/settings/notifications/${id}` )
		);
	};

	const deleteNotification = ( id: number ): void => {
		confirmAlert( {
			customUI: ( { onClose } ): any => (
				<div className="Prompt">
					<h2>{t( 'settings.notifications.prompt.title' )}</h2>
					<p>{t( 'settings.notifications.prompt.info' )}</p>
					<div className="Prompt__container">
						<p aria-hidden="true" onClick={onClose}>
							<strong>{t( 'settings.notifications.prompt.links.abort' )}</strong>
						</p>
						<p
							aria-hidden="true"
							onClick={() => {
								HeyServer.put( `/notification/${id}/`, { is_deleted: true } )
									.then( () => mutate( ['/notification/', true] ) )
									.finally( () => {
										route.push( '/settings/notifications' );
										onClose();
									} );
							}}
						>
							<strong>{t( 'settings.notifications.prompt.links.delete' )}</strong>
						</p>
					</div>
				</div>
			)
		} );
	};

	// wait for the tags to be set in state
	if ( !tags ) {
		return <Loader />;
	}

	const isSettingsTabActive = tags.length > 2 && tags[2].active;
	const isReadTabActive = tags[1].active;
	const isUnreadTabActive = tags[0].active;

	return (
		<Page title={t( 'settings.notifications.title' )}>
			<TagList type="singular" onChange={setTags} tags={tags} />
			<br />
			{isSettingsTabActive && (
				<Card>
					{serverError && (
						<Alert type="error" show>
							<p>{ serverError }</p>
						</Alert>
					)}
					<div style={{ padding: '0 10px' }}>
						<div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center'
								}}
							>
								<Switch
									{...switchStyle}
									onChange={( v ) => {
										updateSettingsTicketUpdates();
									}}
									checked={user.push_reminders}
								/>
								<h3 style={{ marginLeft: 10 }}>
									{t( 'settings.notifications.settings.tickets.label' )}
								</h3>
							</div>
							<p>
								{t( 'settings.notifications.settings.tickets.text' )}
								<ul>
									<li>{t( 'settings.notifications.settings.tickets.li_1' )}</li>
									<li>{t( 'settings.notifications.settings.tickets.li_2' )}</li>
								</ul>
							</p>
						</div>
						<Divider />
						<div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center'
								}}
							>
								<Switch
									{...switchStyle}
									onChange={( v ) => {
										updateSettingsAds();
									}}
									checked={user.push_adverts}
								/>
								<h3 style={{ marginLeft: 10 }}>
									{t( 'settings.notifications.settings.ads.label' )}
								</h3>
							</div>
							<p>{t( 'settings.notifications.settings.ads.text' )}</p>
						</div>
					</div>
				</Card>
			)}
			{( isUnreadTabActive || isReadTabActive ) &&
				generateList()
					.filter( ( el ) => !el.is_deleted )
					.map( ( { id, title, publish_at } ) => (
						<Card>
							<div aria-hidden="true" className="NotificationListItem">
								<div
									aria-hidden="true"
									className="data"
									onClick={() => itemClicked( id )}
								>
									<span className="title">{title}</span>
									<span className="date">
										{moment( publish_at ).format( 'DD.MM.YYYY' )}
									</span>
								</div>
								<div
									className="__deleteIcon"
									aria-hidden
									onClick={() => deleteNotification( id )}
								>
									<TrashSVG />
								</div>
							</div>
						</Card>
					) )}
		</Page>
	);
};
