import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import common_en from './locales/en/common.json';
import custom_en from './locales/en/custom.json';
import common_de from './locales/de/common.json';
import custom_de from './locales/de/custom.json';

const resources = {
	en: {
		common: common_en,
		custom: custom_en
	},
	de: {
		common: common_de,
		custom: custom_de
	}
};

i18n
	.use( detector )
	.use( initReactI18next ) // passes i18n down to react-i18next
	.init( {
		resources,
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	} );

export default i18n;
