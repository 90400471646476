import { FC, ReactNode } from 'react';
import { ReactComponent as Icon } from '../../assets/vectors/arrow-right.svg';
import { ReactComponent as IconB } from '../../assets/vectors/chevron-down.svg';

export const Input: FC<InputProps> = ( {
	placeholder,
	readOnly,
	onChange,
	value,
	disabled = false,
	label = '',
	type = 'no-label',
	id = '',
	child = null,
	name = '',
	required = false,
	inputType = 'text',
	selectValues = [],
	cy = '',
	autoComplete = 'none',
	onButtonPress = () => false,
	onErrorMessage = '',
	onSuccessMessage = '',
	onFocus = () => true,
	onBlur = () => true,
	overrideClassName = null
}: InputProps ) => {
	const classNames: string[] = ['__Input'];
	onErrorMessage && classNames.push( `__Input-error` );
	onSuccessMessage && classNames.push( `__Input-success` );
	type && classNames.push( `__Input-${type}` );
	overrideClassName && classNames.push( overrideClassName );
	const className: string = classNames.join( ' ' );
	return (
		<div className="__Input-container">
			{child && child}
			{label && type === 'with-label' && <label htmlFor={name}>{label}</label>}
			{inputType === 'select' ? (
				<>
					<div className="__Input-dropdown-icon">
						<IconB />
					</div>
					<select
						id={id}
						name={name}
						value={value}
						onChange={( e ) => onChange( e.target.value, e )}
						className={className as string}
					>
						{selectValues.map( ( { optionLabel, optionValue } ) => (
							<option value={optionLabel}>{optionValue}</option>
						) )}
					</select>
				</>
			) : (
				<input
					autoComplete={autoComplete}
					required={required}
					readOnly={readOnly}
					onChange={( e ) => onChange( e.target.value, e )}
					value={value}
					disabled={disabled}
					type={inputType}
					data-cy={cy}
					className={className as string}
					id={id}
					name={name}
					placeholder={placeholder}
					onFocus={onFocus}
					onBlur={onBlur}
				/>
			)}
			{type === 'with-button' && onButtonPress && (
				<button
					disabled={disabled}
					type="button"
					onClick={onButtonPress}
					className="__Input-button"
				>
					<Icon />
				</button>
			)}
			{onErrorMessage && (
				<span className="__Input-error-message">{onErrorMessage}</span>
			)}
			{onSuccessMessage && (
				<span className="__Input-success-message">{onSuccessMessage}</span>
			)}
		</div>
	);
};

export interface InputProps {
	/**
	 * Class name
	 */
	overrideClassName?: string | null;
	/**
	 * HTML attribute placeholder
	 */
	placeholder?: string;
	/**
	 * Callback Input Value Changes
	 */
	onChange: ( val: any, e: any ) => any;
	/**
	 * Input type
	 */
	inputType?: string;
	/**
	 * Input value
	 */
	value: string | number;
	/**
	 * Toggle deactivation
	 */
	disabled?: boolean;
	/**
	 * Input text label
	 */
	label?: string;
	/**
	 * Select values
	 */
	selectValues?: {
		optionLabel: string;
		optionValue: string;
	}[];
	/**
	 * The style type of the input
	 */
	type?: 'no-label' | 'with-label' | 'with-button';
	/**
	 * Callback Input Value Changes
	 */
	onButtonPress?: () => any;
	/**
	 * HTML attribute id
	 */
	id?: string;
	/**
	 * HTML attribute name
	 */
	name: string;
	/**
	 * Condition AND message for rendering a error message box below the input
	 */
	onErrorMessage?: string;
	/**
	 * Condition AND message for rendering a success message box below the input
	 */
	onSuccessMessage?: string;
	readOnly?: boolean;
	child?: ReactNode;
	autoComplete?: string;
	required?: boolean;
	cy?: string;
	onFocus?: () => any;
	onBlur?: () => any;
}
Input.defaultProps = {
	disabled: false,
	type: 'no-label',
	cy: '',
	label: '',
	id: '',
	name: '',
	onErrorMessage: '',
	onSuccessMessage: ''
};
