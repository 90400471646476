// eslint-disable jsx-props-no-spreading
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */

import { useState, Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { mutate } from 'swr';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { getCustomerTypes, getOrder, ShopPresistor } from './Shop.data';
import { ReactComponent as TrashSVG } from '../../assets/vectors/trash-2.svg';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { ReactComponent as PlusSVG } from '../../assets/vectors/plus.svg';
import { Order } from '../../types/Models';
import { getUser } from '../Settings/Settings.data';
import { Divider } from '../../components/Divider';
import { formatPrice } from '../../utils/helpers';
import { HeyServer } from '../../utils/server';
import { getSettings } from '../Settings/Settings.data';

export default (): any => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );

	// redirect back to first step of params are corrupt
	const activeOrder: any = ShopPresistor().getActiveOrder();
	const [ discountCode, setDiscountCode ] = useState( '' );
	const [ serverCouponError, setServerCouponError ] = useState( '' );

	// when new price is being retrieved from the server
	const [ submitting, setSubmitting ] = useState( false );

	// SWR calls
	const { data: userData, isLoading: userIsLoading } = getUser( true );
	const { data, isLoading, isError } = getOrder( true, activeOrder.pk as number );
	const { data: customerTypeData, isLoading: customerIsLoading } = getCustomerTypes( false );
	const { data: settingsData, isLoading: settingsLoading } = getSettings( false );

	// methods
	const renderEmptyCart = () => (
		<Page title={ t( 'basket.title' ) }>
			<div className="EmptyCartContainer">
				<Card>
					<p>{ t( 'basket.empty_info' ) }</p>
					<h1>{ t( 'cart_cta_txt', { ns: 'custom' } ) }</h1>
					<Button
						onClick={ () => route.push( '/shop' ) }
						label={ t( 'buttons.select_ticket' ) }
					/>
				</Card>
			</div>
		</Page>
	);

	const getCustomerType = ( id: number ) => {
		const f = customerTypeData.results.find( ( { id: cid } ) => cid === Number( id ) );

		if ( ! f )
			return 'student';

		return f.name;
	};

	const updateOrder = ( res: any ) => {
		// ignore any null orders
		if ( ! res )
			return;

		// Update the order
		mutate( [`/order/?id=${res.pk}`, true] );
		setServerCouponError( '' );
		ShopPresistor().setActiveOrder( res );

		// Disable loader, but with timeout to prevent user from deleting the same ticket.
		setTimeout( () => { setSubmitting( false ); }, 500 );
	};

	const resolveDiscountCode = (
		customCode = '',
		extraPackageIncertInfo: any = null
	) => {
		let code = discountCode;
		if ( customCode )
			code = customCode;

		let load = { code };

		if ( extraPackageIncertInfo )
			load = extraPackageIncertInfo;

		if ( ! load.code )
			return;

		HeyServer.post( `order/${activeOrder.pk}/code/`, JSON.stringify( load ) )
			.then( ( res: any ) => updateOrder( res ) )

			.catch( ( e ) => {
				setServerCouponError( e );
			} )

			.finally( () => {
				setTimeout( () => setSubmitting( false ), 500 );
				setDiscountCode( '' );
			} );
	};

	const removeDiscountCode = ( code: string ) => {
		HeyServer.delete( `order/${order.pk}/code/`, { data: JSON.stringify( { code } ) } )
		.then( ( res: any ) => updateOrder( res ) )

		.catch( ( e ) => {
			setServerCouponError( e );
			setSubmitting( false );
		} );
	};

	const removeTicket = ( ticketId: number ) => {
		setSubmitting( true );

		HeyServer.delete( `order/${order.pk}/ticket/`, { data: JSON.stringify( { id: ticketId } ) } )
			.then( ( res: any ) => updateOrder( res ) )

			.catch( ( e ) => {
				setServerCouponError( e );
				setSubmitting( false );
			} );
	};

	// dont proceed until loaded
	const syncCoupons = () => {
		// add coupon from URL
		const ic = ShopPresistor().getFlagData( 'incert_coupon' );
		const iv = ShopPresistor().getFlagData( 'incert_voucher' );
		const ip = ShopPresistor().getFlagData( 'incert_package' );
		const newCode = ic.code || iv.code || ip.code;
		resolveDiscountCode( newCode, ip );
		ShopPresistor().flagReset();
	};

	// local data: if user deletes items in the cart, or cart has no tickets or ID, then render empty cart
	if (
		isError ||
		! activeOrder ||
		! activeOrder.pk ||
		activeOrder.tickets.length < 1
	) {
		syncCoupons();
		return renderEmptyCart();
	}

	// wait for servers to load
	if ( isLoading || customerIsLoading || userIsLoading || settingsLoading )
		return <Loader />;

	const order = data.results[0];

	// same check as above, except it is for server results, just incase
	if ( ! data || ! order || order.tickets.length < 1 )
		return renderEmptyCart();

	ShopPresistor().setActiveOrder( order );

	// set coupons from order
	let orderCoupon: Order | any = null;
	if ( order.coupons_used && order.coupons_used.length > 0 )
		orderCoupon = order.coupons_used[0];

	// set incert vouchers from order
	let orderIncert: Order | any = null;
	if ( order.incert_vouchers && order.incert_vouchers.length > 0 )
		orderIncert = order.incert_vouchers[0];

	// apply coupon
	syncCoupons();

	const currentUser = userData.results[0];
	const settings = settingsData.results[0];

	const repromptTos = moment( currentUser.tos_accepted ).isBefore( settings.terms_of_service_date );
	const repromptPp  = moment( currentUser.pp_accepted ).isBefore( settings.privacy_policy_date );
	const anyReprompt = repromptTos || repromptPp;

	return (
		<Page title={ t( 'basket.title' ) }>
			<div className="CartContainer">
				<Card>
					<Loader show={ submitting } mode="overlay" />

					<div className="CartHeader">
						<h1>{ t( 'basket.title' ) }</h1>
						<Button
							type="secondary"
							label={ t( 'basket.add_ticket' ) }
							Icon={ <PlusSVG /> }
							onClick={ () => route.push( '/shop' ) }
						/>
					</div>

					<Divider />

					<div className="CartBody">
						{ order.tickets.map(
							( {
								typ,
								contacts,
								voucher,
								price,
								id: ticketId
							}: any | Order ) => (
								<Fragment key={ ticketId }>
									<div className="CartItem">
										<div className="CartItem__left">
											<h1>{ typ.ticket_type.name }</h1>
											<div className="CartItem__left--container">
												<p>
													{ typ.customer_types.map(
														(
															{ count, customer_type }: any | Order,
															i
														) =>
															`${count} ${getCustomerType( customer_type )}${
																i !== typ.customer_types.length - 1 ? ', ' : ''
															}`
													) }
												</p>
												<span>
													{ [ ...contacts ]
														.reverse()
														.map(
															( { contact }: any | Order, i ) =>
																`${contact.name}${
																	i !== contacts.length - 1 ? ', ' : ''
																} `
														)
													}
												</span>
											</div>
										</div>
										<div className="CartItem__right">
											<div
												className="CartItem__right--deleteIcon"
												aria-hidden
												onClick={  () => removeTicket( ticketId )  }
											>
												<TrashSVG />
											</div>
											{ voucher && <span>Gutschein eingelöst</span> }
											{ ! voucher && <h1>{ formatPrice( price ) }</h1> }
										</div>
									</div>
									<Divider />
								</Fragment>
							)
						) }
						{ orderCoupon && (
							<div className="CartItem">
								<div className="CartItem__left">
									<h1>Rabatt-Coupon</h1>
									<span>Rabattcode: {orderCoupon.code}</span>
								</div>
								<div className="CartItem__right">
									<div
										className="CartItem__right--deleteIcon"
										aria-hidden
										onClick={ () => removeDiscountCode( orderCoupon.code ) }
									>
										<TrashSVG />
									</div>
									<h1>
										- { Number( orderCoupon.typ.value_percent ).toFixed( 2 ) } %
									</h1>
								</div>
							</div>
						) }
						{ orderIncert && (
							<div className="CartItem">
								<div className="CartItem__left">
									<h1>Rabatt-Coupon</h1>
									<span>Rabattcode: { orderIncert.code }</span>
								</div>

								<div className="CartItem__right">
									<div
										className="CartItem__right--deleteIcon"
										aria-hidden
										onClick={() => removeDiscountCode( orderIncert.code )}
									>
										<TrashSVG />
									</div>

									<h1>- { Number( orderIncert.value ).toFixed( 2 ) } €</h1>
								</div>
							</div>
						) }
					</div>

					<Divider />
					<div className="CartSummary">
						<p>{ t( 'basket.sum' ) }</p>

						<p>{ formatPrice( order.payment_sum ) }</p>
					</div>

					<Divider />

					<div className="CartFooter">
						<Input
							name="code"
							value={ discountCode }
							placeholder={ t( 'basket.code_placeholder' ) }
							onChange={ setDiscountCode }
							onErrorMessage={ serverCouponError }
						/>
					</div>

					<Divider />

					<div className="CartCTA">
						<Button
							label={ discountCode ? t( 'basket.redeem' ) : t( 'basket.to_payment' ) }
							onClick={ () =>
								discountCode
									? resolveDiscountCode()
									: currentUser.country !== 'AT' ||
									  parseFloat( order.total_price ) >= 400 ||
									  anyReprompt
									? route.push( '/shop/billing' )
									: route.push( '/shop/payment' )
							}
						/>
					</div>
				</Card>
			</div>
		</Page>
	);
};
