import useSWR, { useSWRInfinite } from 'swr';
import i18n from 'i18next';
import axios, { AxiosResponse } from 'axios';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import { CustomerType, Order } from '../../types/Models';
import { SWRReturnObject } from '../../types/Types';
import { TicketDataType } from '../../components/modular/CartSummary';
import { getLocalResource, HeyServer } from '../../utils/server';
import { formatDeutschDate } from '../../utils/helpers';

export type TypeGetPricePayload = {
	ticket_type: number;
	customer_types: {
		customer_type_id: number;
		count: number;
	}[];
};

export const getTickets = (
	forceAPICall: boolean,
	pageIndex = 1
): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [
		`/ticket/?ordering=valid_from&page=${pageIndex}`,
		forceAPICall
	] );

	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};

export const getOrder = (
	forceAPICall: boolean,
	orderId: number
): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [`/order/?id=${orderId}`, forceAPICall] );
	return {
		data,
		isLoading: !error && !data,
		isError: error || !orderId
	};
};
export const getContact = ( forceAPICall: boolean ): any => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error, setSize, size }: any = useSWRInfinite( ( pageIndex ) => [
		`/contact/?page=${pageIndex + 1}`,
		forceAPICall
	] );
	if ( size === 1 ) {
		ShopPresistor().resetContacts();
	}
	return {
		data,
		setSize,
		size,
		isLoadingInitialData: !error && !data,
		isLoadingMore:
			( !error && !data ) ||
			( size > 0 && data && typeof data[size - 1] === 'undefined' ),
		isEmpty: data?.[0]?.length === 0,
		isError: error
	};
};

export const getTicketTypes = ( forceAPICall: boolean ): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [`/ticket_type/`, forceAPICall] );
	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};

export const getCustomerTypes = ( forceAPICall: boolean ): SWRReturnObject => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { data, error } = useSWR( [`/customer_type/`, forceAPICall] );
	return {
		data,
		isLoading: !error && !data,
		isError: error
	};
};

export const getPrice = (
	customerTypeCountMapping: any[],
	ticket_type_id: number
) => {
	const payload: TypeGetPricePayload = {
		ticket_type: ticket_type_id,
		customer_types: []
	} as any;
	payload.customer_types = customerTypeCountMapping.map(
		( { counterValue: count, customer_type_id } ) => ( {
			customer_type_id,
			count
		} )
	);
	payload.customer_types = _.uniqBy( payload.customer_types, 'customer_type_id' );

	const process = new Promise( ( resolved, rejected ) =>
		HeyServer.post( 'order/price_preview/', JSON.stringify( payload ) )
			.then( ( { price }: any ) => resolved( price ) )
			.catch( ( e ) => rejected( e ) )
	);
	return process;
};
// shop presistence
export interface CustomerTypeCustom {
	rebate_card_id?: number;
	contact_id?: number;
	customer_type_info: CustomerType;
	formEntries: {
		image: string;
		name: string;
		birthday: Moment | string;
	};
	formErrors: {
		image: string;
		name: string;
		birthday: string;
	};
}

// sometimes we wanna turn on/off flags when for e.g a certain wizard is on going like incert packge,
// so that the other parts system knows
export type TypeFlagName =
	| 'incert_package'
	| 'incert_coupon'
	| 'incert_voucher';
export type ShopStateType = {
	contacts: any;
	flags: {
		[flagName in TypeFlagName]: {
			status: boolean;
			payload: string;
		};
	};
	active_order: Order;
	active_ticket_selection: {
		ttid?: number | null;
		date?: string | null;
		summary?: TicketDataType | null;
		pax_type_count?: number[] | [];
		pax_infos?: CustomerTypeCustom[] | [];
	};
};

export const initialShopState: ShopStateType = {
	contacts: [],
	flags: {
		incert_package: {
			status: false,
			payload: '{}'
		},
		incert_coupon: {
			status: false,
			payload: '{}'
		},
		incert_voucher: {
			status: false,
			payload: '{}'
		}
	},
	active_order: {},
	active_ticket_selection: {
		ttid: null,
		date: null,
		summary: {
			count: 0,
			name: '',
			price: 0
		},
		pax_type_count: [],
		pax_infos: []
	}
};

export const ShopPresistor = () => {
	const get = () => {
		const r = getLocalResource( 'shop_state' ) as unknown as ShopStateType;
		if ( !r ) {
			init();
		}
		return r;
	};
	const init = (): void => {
		if ( window.localStorage.getItem( 'shop_state' ) == null ) {
			window.localStorage.setItem(
				'shop_state',
				JSON.stringify( initialShopState )
			);
		}
	};
	const getContacts = () => get().contacts;
	const getActiveOrder = () => get().active_order;
	const getFlags = () => get().flags;
	const getFlagData = ( flagName: TypeFlagName ) =>
		JSON.parse( get().flags[flagName].payload );
	const getSelections = () => get().active_ticket_selection;
	const resetContacts = () => {
		const payload = get();
		payload.contacts = [];
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};
	const setContacts = ( newContacts: any ) => {
		const payload = get();
		payload.contacts = [...newContacts, ...payload.contacts];
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};
	const resetState = ( ticket_type_id = null ) => {
		const payload = get();
		payload.active_ticket_selection = {
			ttid: ticket_type_id,
			pax_type_count: [],
			summary: { count: 0, name: '', price: 0 },
			pax_infos: [],
			date: null
		};
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};
	const flagOn = ( flagName: TypeFlagName, data: any = {} ) => {
		const payload = get();
		payload.flags[flagName].status = true;
		if ( data ) {
			payload.flags[flagName].payload = JSON.stringify( data );
		}
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};
	const flagOff = ( flagName: TypeFlagName ) => {
		const payload = get();
		payload.flags[flagName].status = false;
		payload.flags[flagName].payload = '{}';
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};
	const flagReset = () => {
		const payload = get();
		payload.flags = initialShopState.flags;
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};

	const setActiveOrder = ( newOrder: Order ) => {
		const payload = get();
		payload.active_order = newOrder;
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};
	const setSelections = (
		newSelection: ShopStateType['active_ticket_selection']
	) => {
		const payload = get();
		payload.active_ticket_selection = {
			...payload.active_ticket_selection,
			...newSelection
		};
		window.localStorage.setItem( 'shop_state', JSON.stringify( payload ) );
	};
	const reset = () =>
		window.localStorage.setItem( 'shop_state', JSON.stringify( initialShopState ) );
	return {
		init,
		resetState,
		getActiveOrder,
		getContacts,
		setContacts,
		resetContacts,
		setActiveOrder,
		getSelections,
		setSelections,
		getFlagData,
		flagOn,
		flagOff,
		flagReset,
		getFlags,
		reset
	};
};
export const downloadQrCodes = ( allTickets: any ) => {
	const requestQueue: Promise<AxiosResponse<any>>[] = [];
	// batch call on card creation first
	allTickets.forEach( ( { id: ticketId } ) =>
		requestQueue.push(
			HeyServer.get( `ticket/${ticketId}/qr/`, {
				responseType: 'arraybuffer'
			} )
		)
	);
	const process = new Promise( ( resolved, rejected ) =>
		axios
			.all( requestQueue )
			.then( ( ...res ) => resolved( res ) )
			.catch( ( e ) => rejected( e ) )
	);
	return process;
};
/// pax info page

export const registerIdCards = ( list: CustomerTypeCustom[] ) => {
	const requestQueue: Promise<AxiosResponse<any>>[] = [];
	// prepare call payload
	const payloads: {
		typ: 'pass' | 'invalid' | 'student' | 'tsp';
		name: string;
	}[] = ( list as any ).map(
		( { customer_type_info, contact_id }: CustomerTypeCustom ) =>
			customer_type_info.rebate_card && {
				name: String( contact_id ), // Using contact id as unique identifier to the pax list
				typ: customer_type_info.rebate_card
			}
	);
	// prepare batch call
	payloads.forEach( ( payload ) => {
		payload &&
			requestQueue.push(
				HeyServer.post( 'rebate_card/', JSON.stringify( payload ) )
			);
	} );
	const process = new Promise( ( resolved, rejected ) =>
		axios
			.all( requestQueue )
			.then(
				axios.spread( ( ...responses: any ) => {
					const newList = [...list];
					newList.forEach( ( el, index ) => {
						responses.forEach( ( res ) => {
							if ( el.contact_id === Number( res.name ) ) {
								newList[index].rebate_card_id = Number( res.id );
							}
						} );
					} );
					resolved( newList );
				} )
			)
			.catch( ( e ) => rejected( e ) )
	);
	return process;
};

export const registerContacts = ( paxList ) => {
	const requestQueue: Promise<AxiosResponse<any>>[] = [];
	// prepare call payload
	const payloads: { name: string; birthday: string }[] = (
		[...paxList] as any
	).map( ( { formEntries } ) => {
		const { name, birthday } = formEntries;
		const load: any = { name };
		if ( birthday ) {
			load.birthday = moment( formatDeutschDate( birthday ) ).format( 'YYYY-MM-DD' );
		}
		return load;
	} );
	// prepare batch call
	payloads.forEach( ( payload ) => {
		requestQueue.push( HeyServer.post( 'contact/', JSON.stringify( payload ) ) );
	} );
	const process = new Promise( ( resolved, rejected ) =>
		axios
			.all( requestQueue )
			.then(
				axios.spread( ( ...responses: any ) => {
					const list = [...responses];
					const newList = [...paxList].map( ( el, index ) => ( {
						...el,
						contact_id: list[index].id,
						birthday: list[index].birthday,
						photo: list[index].photo
					} ) );
					resolved( newList );
				} )
			)
			.catch( ( e ) => rejected( e ) )
	);
	return process;
};

export const uploadIdCards = ( list: CustomerTypeCustom[] ) => {
	const requestQueue: Promise<AxiosResponse<any>>[] = [];
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};
	// batch call on card creation first
	list.forEach( ( { formEntries, rebate_card_id } ) => {
		if ( formEntries.image ) {
			const formData = new FormData();
			let { image }: any = formEntries; // possible TS break
			// convet base64 to File object
			fetch( image )
				.then( ( res ) => res.blob() )
				.then( ( blob ) => {
					image = new File( [blob], 'File name', { type: 'image/png' } );
					formData.append( 'file', image );
					requestQueue.push(
						HeyServer.post(
							`rebate_card/${rebate_card_id}/photo/`,
							formData,
							config
						)
					);
				} );
		}
	} );
	const process = new Promise( ( resolved, rejected ) =>
		axios
			.all( requestQueue )
			.then( () => resolved( true ) )
			.catch( ( e ) => rejected( e ) )
	);
	return process;
};

export const getStripeError = ( code: string ): string => {
	const options = { ns: 'common' };

	switch ( code ) {
		case 'card_declined':
		case 'invalid_number':
		case 'invalid_expiry_month':
		case 'expired_card':
			return i18n.t( `errors.payment.${code}`, options );

		case 'incorrect_number':
		case 'incomplete_number':
			return i18n.t( 'errors.payment.card_number', options );

		case 'invalid_expiry_year':
		case 'invalid_expiry_year_past':
			return i18n.t( 'errors.payment.expiry_year', options );

		case 'incorrect_zip':
		case 'incomplete_zip':
			return i18n.t( 'errors.payment.zip', options );

		case 'invalid_cvc':
		case 'incorrect_cvc':
		case 'incomplete_cvc':
			return i18n.t( 'errors.payment.cvc', options );

		default:
			return i18n.t( 'errors.payment.default', options );
	}
};
