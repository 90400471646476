import { FC, ReactNode } from 'react';

export const Card: FC<CardProps> = ( {
	children,
	key = Math.floor( Math.random() ),
	onClick = () => true
}: CardProps ) => {
	const classNames: string[] = ['Card'];
	const className: string = classNames.join( ' ' );
	return (
		<div
			key={key}
			onClick={onClick}
			aria-hidden
			className={className as string}
		>
			{children}
		</div>
	);
};

export interface CardProps {
	onClick?: () => void;
	key?: string | number;
	/**
	 * Contents of the Card
	 */
	children: ReactNode;
}
Card.defaultProps = {};
