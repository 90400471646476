import { RouteObject } from '../../types/Types';
import SettingsPage from './Settings.page';
import PublicSettings from './PublicSettings.page';
import Licenses from './Licenses.page';
import Help from './Help.page';
import BillingInfo from './BillingInfo.page';
import ChangePassword from './ChangePassword.page';
import Notifications from './Notifications.page';
import NotificationDetails from './NotificationDetails.page';
import Orders from './Orders.page';

export default [
	{
		name: 'settings_page',
		link: '/settings',
		availableOffline: true,
		component: <SettingsPage />,
		isProtected: false
	},
	{
		name: 'billingInfo_page',
		link: '/settings/billing-info',
		component: <BillingInfo />,
		isProtected: true
	},
	{
		name: 'orders_page',
		link: '/settings/orders',
		component: <Orders />,
		isProtected: true
	},
	{
		name: 'changePassword_page',
		link: '/settings/password-change',
		component: <ChangePassword />,
		isProtected: true
	},
	{
		name: 'notifications_page',
		link: '/settings/notifications',
		availableOffline: true,
		component: <Notifications />,
		isProtected: true
	},
	{
		name: 'notifications_details_page',
		availableOffline: true,
		link: '/settings/notifications/:notification_id',
		component: <NotificationDetails />,
		isProtected: true
	},
	{
		name: 'policy_page',
		link: '/settings/policy',
		availableOffline: true,
		component: <PublicSettings type="policy" />,
		isProtected: false
	},
	{
		name: 'terms_page',
		link: '/settings/terms',
		availableOffline: true,
		component: <PublicSettings type="terms" />,
		isProtected: false
	},
	{
		name: 'licenses_page',
		link: '/settings/licenses',
		availableOffline: true,
		component: <Licenses />,
		isProtected: false
	},
	{
		name: 'help_page',
		link: '/settings/help',
		availableOffline: true,
		component: <Help />,
		isProtected: false
	}
] as unknown as RouteObject[];
