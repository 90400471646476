import { FC, useState, useEffect } from 'react';
import { ReactComponent as XIcon } from '../../../assets/vectors/x.svg';

export const TagItem = ( {
	label,
	active,
	onSelect,
	onClose,
	type,
	key
}: TypeTagItemComponent ): JSX.Element => (
	<button
		key={key}
		type="button"
		className={`TagList__item ${active ? ' active' : ''}`}
		onClick={() => ( active ? onClose( label ) : onSelect( label ) )}
	>
		<p>{label}</p>
		{active && type === 'multi' && (
			<button
				onClick={() => onClose( label )}
				className="TagList__close"
				type="button"
			>
				<XIcon />
			</button>
		)}
	</button>
);

export const TagList: FC<TagListProps> = ( {
	type,
	tags,
	onChange
}: TagListProps ) => {
	const [list, setList] = useState( [...tags] );
	useEffect( () => {
		onChange( list );
	}, [list] );
	const onToggle = ( _label: string, toggleTo: boolean ) => {
		const copyList = [...list];
		// if singular, deselect all tags first then selected the requested one
		if ( type === 'singular' ) {
			copyList.forEach( ( el, i ) => {
				copyList[i].active = false;
			} );
		}
		const objIndex = copyList.findIndex( ( { label } ) => _label === label );
		if ( type === 'singular' && toggleTo === false ) {
			copyList[objIndex].active = true;
		} else {
			copyList[objIndex].active = toggleTo;
		}
		setList( copyList );
	};
	return (
		<div className="TagList">
			{list.map( ( { label, active } ) => (
				<TagItem
					key={label}
					type={type}
					label={label}
					active={active}
					onSelect={( _label: string ) => onToggle( _label, true )}
					onClose={( _label: string ) => onToggle( _label, false )}
				/>
			) )}
		</div>
	);
};
export type TypeTagItem = {
	label: string;
	active: boolean;
};
export type TypeTagItemComponent = {
	label: string;
	type: TagListProps['type'];
	active: boolean;
	key: string;
	onSelect: ( label: string ) => void;
	onClose: ( label: string ) => void;
};
export interface TagListProps {
	/**
	 * List if items to be rendered
	 */
	tags: TypeTagItem[];
	/**
	 * Returns the updated list
	 */
	onChange: ( list: TypeTagItem[] ) => void;
	/**
	 * Supported Types of Tag Component
	 */
	type: 'multi' | 'singular';
}
TagList.defaultProps = {};
