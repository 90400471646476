import { useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

// Hook
export const useRouter = () => {
	const params = useParams();
	const location: any = useLocation();
	const history = useNavigate();
	// Return our custom router object
	// Memoize so that a new object is only returned if something changes
	return useMemo(
		() => ( {
			// For convenience add push(), replace(), pathname at top level
			location,
			push: ( p, customState: any = null ) => {
				let state: any = {
					from: location.pathname,
					id: 'TT2N-APP'
				};
				if ( customState ) {
					state = { ...state, ...customState };
				}
				history( p, { state } );
			},
			pathname: location.pathname,
			goBack: () => history( -1 ),
			// Merge params and parsed query string into single "query" object
			// so that they can be used interchangeably.
			// Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
			query: {
				...queryString.parse( location.search ), // Convert string to object
				...params
			} as any,
			// Include match, location, history objects so we have
			// access to extra React Router functionality if needed.
			previousPath:
				location.state && location.state.from && location.state.from.pathname
		} ),
		[params, location, history]
	);
};
