import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { getSettings } from './Settings.data';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { capitalize } from '../../utils/helpers';

type PublicSettingPages = 'policy' | 'terms';
const PolicyPage = ( { type }: { type: PublicSettingPages } ): JSX.Element => {
	const { data, isLoading } = getSettings( false );
	const route = useRouter();

	if ( isLoading )
		return <Loader />;

	let content = '';
	const policy = data.results[0].privacy_policy;
	const terms = data.results[0].terms_of_service;

	switch ( type ) {
		case 'policy':
			content = policy;
			break;

		case 'terms':
			content = terms;
			break;

		default:
			route.push( '/' );
			break;
	}

	return (
		<Page title={ capitalize( type ) }>
			<Card>
				<div
					style={ {
						padding: '10px 20px',
						overflow: 'auto',
						overflowX: 'hidden'
					} }
					dangerouslySetInnerHTML={ { __html: content } }
				/>
			</Card>
		</Page>
	);
};

export default PolicyPage;
