import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from '../../../utils/helpers';
import { Button } from '../../Button';

export const CartSummary: FC<CartSummaryProps> = ( {
	btnLabel,
	ticketData,
	disabled,
	onClick
}: CartSummaryProps ) => {
	const { name, count, price } = ticketData;
	const [ t ] = useTranslation( 'common' );
	return (
		<div className="CartSummary">
			<div>
				<div className="CartSummary__items">
					<span>
						{ count } x { name }
					</span>
				</div>

				<div className="CartSummary__total">
					<p>{ t( 'basket.sum' ) } </p>

					<span>{ formatPrice( price ) }</span>
				</div>
			</div>

			<Button
				type="secondary"
				size="lg"
				label={ btnLabel }
				disabled={ disabled }
				onClick={ onClick }
			/>
		</div>
	);
};

export type TicketDataType = {
	count: number;
	name: string;
	price: number;
};

export interface CartSummaryProps {
	/**
	 * Button Label
	 */
	btnLabel: string;
	disabled?: boolean;

	/**
	 * Button Label
	 */
	ticketData: TicketDataType;

	/**
	 * When the CTA button is clicked
	 */
	onClick: () => void;
}

CartSummary.defaultProps = {};
